import React, {useEffect} from 'react';
import { BrowserRouter, Route, Switch} from "react-router-dom"
import {useSelector} from 'react-redux'
import styled from "styled-components"
import './App.css'
import GlobalNav from "./components/GlobalNav"
import Home from "./components/Home"
import About from "./components/About"
import StorySelect from "./components/StorySelect"
import Stories from "./components/Stories"
import ChildPages from "./components/ChildPages"
import ChildStories from "./components/ChildStories"
import StoryPages from "./components/StoryPages"
import breakpoints from "./components/mixins/breakpoints"

import NotFound from "./components/NotFound"

const AppContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  font-family: 'Montserrat', sans-serif;
`;

const AppMain = styled.main`
  height: 100vh;
  width: 100%;
  background: darkgray;
  margin-left: 0;
  &.onHome {
    width: calc( 100% - 80px );
    margin-left: 80px;
  }
  @media ${breakpoints.device.sp_ls} {
    &.onHome {
      width: 100%;
      height: calc(100vh - 60px);
      margin-left: 0;
    }
  }
  @media ${breakpoints.device.sp_pr} {
    &.onHome {
      width: 100%;
      height: calc(100vh - 60px);
      margin-left: 0;
    }
  }
`;

function App() {
  // const location = useLocation()
  const is_on_pages = useSelector((state) => state.session.is_on_pages)
  const is_on_home = useSelector((state) => state.session.is_on_home)

  return (
    <BrowserRouter>
      <AppContainer className="AppContainer">
        {
          is_on_home ? 
          <GlobalNav /> : <></>
        }
        <AppMain className={is_on_home ? "onHome" : ""}>
          <Switch>
            {/* TOP */}
            <Route exact path="/" component={Home} />

            {/* このサイトについて */}
            <Route exact path="/about" component={About} />

            {/* 物語を読む */}
            <Route exact path ="/story-select" component={StorySelect}/>
            <Route exact path ="/stories/:id" component={Stories}/>
            <Route exact path ="/story/child" component={ChildStories}/>

            {/* ストーリー内ページ選択時 */}
            <Route exact path="/child/pages/:page_id" component={ChildPages}/>
            <Route exact path="/story/pages/:page_id" component={StoryPages}/>

            {/* NotFound */}
            <Route path="404" component={NotFound} />

          </Switch>
        </AppMain>
      </AppContainer>
    </BrowserRouter>
  )
}

export default App;
