import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toggleKanaSwitch } from '../reducks/kanaSwitch/actions'
import styled from 'styled-components'
import color from "./mixins/color"
import breakpoints from "./mixins/breakpoints"


function KanaSwitcher() {

  const kanaSwitch = useSelector(state => state.kanaSwitch)
  const dispatch = useDispatch()

  const handleClick = () => {
    if (kanaSwitch === true) {
      dispatch(toggleKanaSwitch())
    } else {
      dispatch(toggleKanaSwitch())
    }
  }

  return (
    <>
      <SwitchText onClick={handleClick}>
        ことば<br />
        スイッチ
      </SwitchText>
      <KanaSwitch onClick={handleClick} className={kanaSwitch ? "child" : ""}>
        { kanaSwitch ? "こども" : "大人" }
      </KanaSwitch>
    </>
  )
}
const SwitchText = styled.p`
  display: block;
  position: absolute;
  top: 30%;
  right: 0;
  left: 0;
  margin: auto;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  color: #ffffff;
  @media ${breakpoints.device.tab_yoko}{
    top: 29%;
  }
  @media ${breakpoints.device.tab_tate}{
    top: 31%;
  }
  @media ${breakpoints.device.sp_pr}{
    display: none;
  }
  @media ${breakpoints.device.sp_ls}{
    display: none;
  }
`
const KanaSwitch = styled.button`
  display: block;
  position: absolute;
  top: 35%;
  right: 0;
  bottom: auto;
  left: 0;
  margin: auto;
  margin: auto;
  width: 40px;
  height: 40px;
  background: #ffffff;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  border-radius: 20px;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
  &.child {
    font-size: 12px;
  }
  @media ${breakpoints.device.sp_ls}{
    right: 12px;
    top: 5px;
    bottom: auto;
    left: auto;
  }
  @media ${breakpoints.device.sp_pr}{
    right: 12px;
    top: 5px;
    bottom: auto;
    left: auto;
  }
`

export default KanaSwitcher
