import firebase from 'firebase'
import '@firebase/firestore'
import ReduxSagaFirebase from 'redux-saga-firebase'

const firebaseApp = firebase.initializeApp({
  apiKey: "AIzaSyA32GZizO7d-qUnioGShMpc6Yt_98oXEg8",
  authDomain: "salmon-sactuary-story.firebaseapp.com",
  databaseURL: "https://salmon-sactuary-story.firebaseio.com",
  projectId: "salmon-sactuary-story",
  storageBucket: "salmon-sactuary-story.appspot.com",
  messagingSenderId: "661540572505",
  appId: "1:661540572505:web:4c4524cb5daa0fa3e1d048",
  measurementId: "G-LLSRHEFEWK"
})

const rsf = new ReduxSagaFirebase(firebaseApp)

export default rsf