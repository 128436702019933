import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { fetchPagesDataRequest } from "../reducks/pages/actions";
import homeSalmon from "../images/icons/home-salmon.svg";
import logo from "../images/logo.png";
import twitter from "../images/icons/twitter.svg";
import fb from "../images/icons/fb.svg";
import breakpoints from "./mixins/breakpoints";

import nenpyoPDF from "../images/nenpyo.pdf";
import KanaSwitcher from "./KanaSwitcher";
import color from "./mixins/color";

import { Link, useLocation } from "react-router-dom";
import { setIsOnHome } from "../reducks/session/actions";

function GlobalNav() {
  const dispatch = useDispatch();
  const kanaSwitch = useSelector(state => state.kanaSwitch);
  const pathName = useLocation().pathname;
  console.log("pathName", pathName);
  const handleClick = () => {
    dispatch(fetchPagesDataRequest());
    dispatch(setIsOnHome(false));
  };

  return pathName.match("/pages") ? (
    <></>
  ) : (
    <GlobalNavSC className={kanaSwitch ? "child" : ""}>
      <NavMenu to="/">
        <div>
          <img src={logo} alt="Home" />
        </div>
      </NavMenu>

      <KanaSwitcher />

      <Icons>
        <p>SHARE</p>
        <div>
          <a class="twitter-share-button" href="https://twitter.com/intent/tweet?text=鮭の聖地の物語デジタル教材" target="_blank" rel="noopener noreferrer">
            <img src={twitter} alt="Twitter" />
          </a>
        </div>
        <div>
          <a href="https://www.facebook.com/sharer/sharer.php?u=https://salmon-heritage-education.jp" target="_blank" rel="nofollow noopener noreferrer">
            <img src={fb} alt="Facebook" />
          </a>
        </div>
      </Icons>

      <SelectStoryButtonNav to={kanaSwitch ? "/story/child" : "/story-select"} onClick={handleClick}>
        ストーリー
      </SelectStoryButtonNav>
      <NenpyoButton target="_blank" href={nenpyoPDF}>
        年表
      </NenpyoButton>
      <KyozaiButton target="_blank" href="http://www.dokyoi.pref.hokkaido.lg.jp/hk/nky/sakenoseichi.htm">
        教材
      </KyozaiButton>
    </GlobalNavSC>
  );
}

export default GlobalNav;

const GlobalNavSC = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  width: 80px;
  height: 100vh;
  background: gray;
  background: ${color.orange_darker}; /* Old browsers */
  background: -moz-linear-gradient(-45deg, #d83c0c 0%, #062d4b 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(-45deg, #d83c0c 0%, #062d4b 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(135deg, #d83c0c 0%, #062d4b 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='${color.orange_darker}', endColorstr='#062d4b',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
  z-index: 1000;

  &.child {
    background: gray;
    background: ${color.orange_darker}; /* Old browsers */
    background: -moz-linear-gradient(-45deg, #e35226 0%, #66ace0 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(-45deg, #e35226 0%, #66ace0 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(135deg, #e35226 0%, #66ace0 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='${color.orange}', endColorstr='#66ACE0',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
  }

  @media ${breakpoints.device.sp_ls} {
    top: 0;
    left: auto;
    width: 100vw;
    height: 50px;
  }

  @media ${breakpoints.device.sp_pr} {
    top: 0;
    left: auto;
    width: 100vw;
    height: 50px;
  }
`;

const NavMenu = styled(Link)`
  width: 80px;
  display: block;
  margin: auto;
  text-align: center;
  color: #ffffff;
  margin-top: 0px;
  padding-top: 10px;
  cursor: pointer;
  position: absolute;
  @media ${breakpoints.device.sp_ls} {
    width: 40px;
    margin-left: 12px;
    padding-top: 5px;
  }
  @media ${breakpoints.device.sp_pr} {
    width: 40px;
    margin-left: 12px;
    padding-top: 5px;
  }

  &:hover {
    div,
    p {
      opacity: 0.6;
    }
  }

  img {
    width: 50px;
    @media ${breakpoints.device.sp_ls} {
      width: 40px;
    }
    @media ${breakpoints.device.sp_pr} {
      width: 40px;
    }
  }
`;

const Icons = styled.div`
  position: absolute;
  bottom: 30px;
  width: 80px;
  text-align: center;
  font-size: 12px;
  font-weight: 700;
  color: #ffffff;
  @media ${breakpoints.device.sp_ls} {
    display: none;
  }
  @media ${breakpoints.device.sp_pr} {
    display: none;
  }
  p {
    margin-bottom: 16px;
  }
  img {
    display: inline-block;
    width: 30px;
    margin-bottom: 10px;
    cursor: pointer;
    &:hover {
      opacity: 0.6;
    }
  }
`;

const NenpyoButton = styled.a`
  display: block;
  position: fixed;
  left: 20px;
  bottom: 30%;
  background: #e69138;
  background: linear-gradient(#f6b26b, #e69138 50%, #f6b26b);
  border-radius: 20px;
  padding: 12px 5px 0;
  color: #ffffff;
  display: inline-block;
  text-align: center;
  width: 40px;
  height: 40px;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
  z-index: 1000;
  &:hover {
    opacity: 0.7;
  }
  &.child {
    font-size: 12px;
  }
  @media ${breakpoints.device.tab_yoko} {
    bottom: 26%;
  }
  @media ${breakpoints.device.tab_tate} {
    bottom: 33%;
  }
  @media ${breakpoints.device.sp_ls} {
    writing-mode: horizontal-tb;
    width: auto;
    height: 16px;
    padding: 0;
    background: transparent;
    padding: 0;
    top: 50%;
    transform: translateY(-50%);
    right: 160px;
    position: absolute;
    left: auto;
  }

  @media ${breakpoints.device.sp_pr} {
    writing-mode: horizontal-tb;
    width: auto;
    height: 16px;
    padding: 0;
    background: transparent;
    padding: 0;
    top: 50%;
    transform: translateY(-50%);
    right: 160px;
    position: absolute;
    left: auto;
  }
`;

const KyozaiButton = styled.a`
  display: block;
  position: fixed;
  left: 20px;
  bottom: 19%;
  background: #35a1c6;
  background: linear-gradient(#58b5ef, #35a1c6 50%, #58b5ef);
  border-radius: 20px;
  padding: 12px 5px 0;
  color: #ffffff;
  display: inline-block;
  text-align: center;
  width: 40px;
  height: 40px;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
  z-index: 1000;
  &:hover {
    opacity: 0.7;
  }
  &.child {
    font-size: 12px;
  }
  @media ${breakpoints.device.tab_yoko} {
    bottom: 20%;
  }
  @media ${breakpoints.device.tab_tate} {
    bottom: 22%;
  }
  @media ${breakpoints.device.sp_ls} {
    writing-mode: horizontal-tb;
    width: auto;
    height: 16px;
    padding: 0;
    background: transparent;
    padding: 0;
    top: 50%;
    transform: translateY(-50%);
    right: 210px;
    position: absolute;
    left: auto;
  }

  @media ${breakpoints.device.sp_pr} {
    writing-mode: horizontal-tb;
    width: auto;
    height: 16px;
    padding: 0;
    background: transparent;
    padding: 0;
    top: 50%;
    transform: translateY(-50%);
    right: 210px;
    position: absolute;
    left: auto;
  }
`;

const SelectStoryButtonNav = styled(Link)`
  position: absolute;
  width: 40px;
  height: 80px;
  background: ${color.orange};
  text-decoration: none;
  text-align: center;
  color: #ffffff;
  font-weight: 700;
  padding: 4px 14px;
  top: 51%;
  left: 0;
  right: 0;
  margin: 0 auto;
  font-size: 12px;
  border-radius: 20px;
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
  &:hover {
    opacity: 0.6;
  }
  @media ${breakpoints.device.sp_ls} {
    writing-mode: horizontal-tb;
    padding: 14px;
    width: auto;
    height: auto;
    top: 50%;
    transform: translateY(-50%);
    background-color: transparent;
    padding: 0;
    font-size: 14px;
    right: 72px;
    left: auto;
    margin: 0;
  }
  @media ${breakpoints.device.sp_pr} {
    writing-mode: horizontal-tb;
    padding: 14px;
    width: auto;
    height: auto;
    top: 50%;
    transform: translateY(-50%);
    background-color: transparent;
    padding: 0;
    font-size: 14px;
    right: 72px;
    left: auto;
    margin: 0;
  }
`;
