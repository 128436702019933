import React, { useState } from 'react'
import styled from 'styled-components';
import {Link} from 'react-router-dom'
import GlobalLinkButton from './parts/GlobalLinkButton'
import Hero1 from '../images/Hero1.jpg'
import Hero2 from '../images/Hero2.jpg'
import Hero3 from '../images/Hero3.jpg'
import Hero4 from '../images/Hero4.jpg'
import color from "./mixins/color"
import breakpoints from "./mixins/breakpoints"


function Stories() {
  const [storyId, setStoryId] = useState(1);
  return (
    <MainContainer>
      <ImgWrapper>
        { storyId === 1 && <MV src={Hero1} alt="story1"/> }
        { storyId === 2 && <MV src={Hero2} alt="story2"/> }
        { storyId === 3 && <MV src={Hero3} alt="story3"/> }
        { storyId === 4 && <MV src={Hero4} alt="story4"/> }
      </ImgWrapper>
      <GlobalLinkButton path="/" labelJp="TOPにもどる" labelEn="Back to HOME"/>
      <TitleWrapper>
        <StoryIndex>Story {storyId}.</StoryIndex>

        { storyId === 1 &&
          <>
            <StoryTitle className="upper story1"><span>The Attractive Salmon to Notsuke</span></StoryTitle>
            <br/>
            <StoryTitle className="lower story1"><span>Peninsula and people</span></StoryTitle>
            <p style={{lineHeight:'36px'}}>世界に開かれた野付半島と<br/>人々を魅了し続けた鮭</p>
          </>
        }
        { storyId === 2 &&
          <>
            <StoryTitle className="upper story2"><span>10,000 Years to Seek for Salmon</span></StoryTitle>
            <StoryTitle className="lower story2"><span>~Symbiosis of human and nature~</span></StoryTitle>
            <p style={{lineHeight:'36px'}}>鮭を求めて一万年<br />〜人と自然の共生〜</p>
          </>
        }
        { storyId === 3 &&
          <>
            <StoryTitle className="upper story3"><span>Industries Nurtured by </span></StoryTitle>
            <StoryTitle className="lower story3"><span>Aizu Clan</span></StoryTitle>
            <p style={{lineHeight:'36px'}}>幕末会津藩士が育てた産業の灯火<br/>〜文化と文化の衝突と交流〜</p>
          </>
        }
        { storyId === 4 &&
          <>
            <StoryTitle className="upper story4"><span>Salmon Story To the Earth</span></StoryTitle>
            <StoryTitle className="lower story4"><span>Future for Re-Symbosis</span></StoryTitle>
            <p style={{lineHeight:'36px'}}>鮭の物語は大地へと続く<br/>〜再び人と自然の共生に向けて〜</p>
          </>
        }

      </TitleWrapper>

      <StoryLink to={`stories/${storyId}`}>ストーリー{storyId}を読む</StoryLink>
        <StoryList>
          <StoryItem className={storyId === 1 && "selected"} onClick={() => setStoryId(1)}><StoryImg src={Hero1} alt="1"/></StoryItem>
          <StoryItem className={storyId === 2 && "selected"} onClick={() => setStoryId(2)}><StoryImg src={Hero2} alt="2"/></StoryItem>
          <StoryItem className={storyId === 3 && "selected"} onClick={() => setStoryId(3)}><StoryImg src={Hero3} alt="3"/></StoryItem>
          <StoryItem className={storyId === 4 && "selected"} onClick={() => setStoryId(4)}><StoryImg src={Hero4} alt="3"/></StoryItem>
        </StoryList>
    </MainContainer>
  )
}

export default Stories

const MainContainer = styled.div`
  background: white;
  height: 100vh;
  padding-left: 90px;
  padding-top: 155px;
  padding-right: 40px;
  @media ${breakpoints.device.tab}{
    padding-left: 12px;
    padding-top: 80px;
    padding-right: 12px;
  }
`

const ImgWrapper = styled.div `
  height: 100vh;
  width: 50%;
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  z-index:0;
  overflow: hidden;
  @media ${breakpoints.device.tab}{
    width: 70%;
  }
`

const MV = styled.img `
  height: 100%;
  position: relative;
  right: 356px;
  @media ${breakpoints.device.tab}{
    right: 0;
  }
`

const TitleWrapper = styled.div`
  font-size: 24px;
  color: #062D4B;
  font-weight: bold;
  position: relative;
  margin-bottom: 80px;
  @media ${breakpoints.device.tab}{
    font-size: 20px;
  }
`
const StoryIndex = styled.p`
  margin-bottom: 20px;
`

const StoryTitle = styled.h1`
  font-size: 56px;
  position: relative;
  z-index: 0;
  display: inline-block;
  &.upper{
    &::before {
      content: "";
      display: inline-block;
      height: 51px;
      background: #F7F7F7;
      position: absolute;
      z-index: -1;
      top: 20px;
      left: -16px;
      width: 100%;
      @media ${breakpoints.device.tab}{
        content: none;
      }
    }
  }
  &.lower{
    margin-bottom: 33px;
    &::before {
      content: "";
      display: inline-block;
      height: 51px;
      background: #F7F7F7;
      position: absolute;
      z-index: -1;
      top: 20px;
      left: -16px;
      width: 100%;
      @media ${breakpoints.device.tab}{
        content: none;
      }
    }
  }
  @media ${breakpoints.device.pc_xl}{
    font-size: 48px;
  }
  @media ${breakpoints.device.tab_large_tate}{
    font-size: 44px;
  }
  @media ${breakpoints.device.tab_tate}{
    font-size: 40px;
  }
  @media ${breakpoints.device.sp_tate_lg}{
    font-size: 36px;
  }
  @media ${breakpoints.device.sp_yoko}{
    font-size: 32px;
  }
  span {
    @media ${breakpoints.device.tab}{
      padding-bottom: 5px;
      background: linear-gradient(transparent 32%, #F7F7F7 0);
      font-weight: bold;
    }
  }
`

const StoryLink = styled(Link)`
  width: 506px;
  height: 80px;
  display: block;
  padding: 32px 0 27px 0;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  background:${color.orange};
  color: #fff;
  box-sizing: border-box;
  margin-bottom: 80px;
  position: relative;
  z-index: 999;
  &:hover {
    opacity: .7;
  }
  @media ${breakpoints.device.tab}{
    width: 80%;
    margin: 0 auto;
    margin-bottom: 80px;
  }
`

const StoryList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  @media ${breakpoints.device.tab}{
    max-width: 535px;
    margin: 0 auto;
  }
`

const StoryItem = styled.li`
  width: 260px;
  height: 178px;
  border-radius: 5px;
  box-shadow: 0 0 8px gray;
  margin-right: 15px;
  margin-bottom: 15px;
  overflow: hidden; 
  position: relative;
  @media ${breakpoints.device.tab}{
    width: calc((100% / 2) - 8px);
    margin-right: 15px;
    margin-bottom: 15px;
  }
  @media ${breakpoints.device.sp_tate_lg}{
    height: 145px;
  }
  &.selected {
    &::after {
      content: "SELECTED";
      display: inline-block;
      width: 260px;
      height: 34px;
      background-color:${color.orange};
      position: absolute;
      top: 69px;
      right: 0;
      color: #fff;
      text-align: center;
      font-weight: bold;
      font-size: 16px;
      padding: 10px;
      z-index: 100;
      @media ${breakpoints.device.tab}{
        width: 100%;
      }
      @media ${breakpoints.device.sp_tate_lg}{
        top: 58px;
      }
    }
    &::before {
      content: "";
      display: inline-block;
      width: 260px;
      height: 178px;
      background: rgba(0,29,51,0.7);
      border-radius: 5px;
      position: absolute;
      z-index: 3;
      @media ${breakpoints.device.tab}{
        width: 100%;
      }
    }
  }
  &:nth-child(2n) {
    @media ${breakpoints.device.tab}{
      margin-right: 0;
    }
  }
  &:last-child {
    margin-right: 0;
  }
`

const StoryImg = styled.img`
  width: 100%;
  height: 100%;
  position: relative;
`