const size = {
  tab: '1024px',
  tab_tate: '600px',
  tab_yoko: '960px',
  tab_large_tate: '768px',
  tab_large_yoko: '1024px',
  sp_tate: '320px',
  sp_yoko: '568px',
  sp_tate_lg: '375px',
  sp_tate_xl: '414px',
  sp_yoko_lg: '667px',
  sp_yoko_xl: '812px',
  pc_xl: '1200px',
}

const device = {
  tab: `(max-width: ${size.tab})`,
  tab_tate: `(max-width: ${size.tab_tate})`,
  tab_yoko: `(max-width: ${size.tab_yoko})`,
  tab_large_tate: `(max-width: ${size.tab_large_tate})`,
  tab_large_yoko: `(max-width: ${size.tab_large_yoko})`,
  sp_tate: `(max-width: ${size.sp_tate})`,
  sp_tate_lg: `(max-width: ${size.sp_tate_lg})`,
  sp_tate_xl: `(max-width: ${size.sp_tate_lg})`,
  sp_yoko: `(max-width: ${size.sp_yoko})`,
  sp_yoko_xl: `(max-width: ${size.sp_yoko_xl})`,
  tab_large_h: `(max-height: ${size.tab})`,
  tab_h: `(max-height: ${size.tab_large_tate})`,
  tab_small_h: `(max-height: ${size.sp_tate_lg})`,
  sp_yoko_xl_h: `(max-height: ${size.sp_tate_xl})`,
  iPhone5_pr : `(max-width: ${size.sp_tate}) and (orientation: portrait)`,
  iPhone8_pr : `(max-width: ${size.sp_tate_lg}) and (orientation: portrait)`,
  iPhone8plus_pr : `(max-width: ${size.sp_tate_xl}) and (orientation: portrait)`,
  iPhoneX_pr : `(max-width: ${size.sp_yoko_xl}) and (orientation: portrait)`,
  iPhone5_ls : `(max-width: ${size.sp_yoko}) and (max-height: ${size.sp_tate})`,
  iPhone8_ls : `(max-width: ${size.sp_yoko_lg}) and (max-height: ${size.sp_tate_lg})`,
  sp_ls : `(max-width: ${size.sp_yoko_xl}) and (max-height: ${size.sp_tate_xl})`,
  sp_pr : `(max-width: ${size.sp_tate_xl}) and (max-height: ${size.sp_yoko_xl})`,
  iPhoneX_ls : `(max-width: ${size.sp_yoko_xl}) and (max-height: ${size.tab_tate_lg})`,
  pc_xl: `(max-width: ${size.pc_xl})`,
}

export default{size, device}