import React, { useEffect } from 'react'
import GlobalLinkButton from './parts/GlobalLinkButton'
import {Link} from 'react-router-dom'
import styled from 'styled-components';
import storiesData from './temporaryData/storiesData'
import {useSelector, useDispatch} from 'react-redux'
import {fetchPagesDataRequest} from '../reducks/pages/actions'
import PrevIcon from "../images/icons/prev-page.svg";
import NextIcon from "../images/icons/next-page.svg";
import color from "./mixins/color"
import breakpoints from "./mixins/breakpoints"
import {setIsOnPages, setStoryId} from "../reducks/session/actions"

function Stories(props) {
  const kanaSwitch = useSelector(state => state.kanaSwitch);
  const pages = useSelector(state => state.pages)
  console.log('pages', pages)
  const dispatch = useDispatch()
  const id = props.match.params.id
  const nextId = Number(props.match.params.id) + 1 
  const prevId = Number(props.match.params.id) - 1
  const Story = storiesData[id - 1]
  console.log('Story', Story)

  useEffect(() => {
    dispatch(fetchPagesDataRequest())
    dispatch(setIsOnPages(false));
  },[dispatch])


  
  return (
    <StoriesMainContainerSC style={{backgroundImage : `url(${Story.background})`}}>

      <StoriesEnHeading>{Story.title_en_first}<br />{Story.title_en_second}</StoriesEnHeading>

      <GlobalLinkButton path="/story-select" labelJp="物語選択に戻る" labelEn="Back to Select Stories" />
      <StoriesContainerSC>
        <StoriesHeaderSC>
          <StoriesNumSC>Story {Story.story_id}.</StoriesNumSC>
          { kanaSwitch ?
            <StoriesHeadingSC>{Story.title_first_kana + Story.title_second_kana}</StoriesHeadingSC>
          :
            <StoriesHeadingSC>{Story.title_first + Story.title_second}</StoriesHeadingSC>
            }
        </StoriesHeaderSC>
        <StoriesBodySC>
          {
            id === '1' 
            ? 
            (
              kanaSwitch ?
              <p>
                　北海道のいちばん東、目の前に国後島（くなしりとう）が見える根室海峡（ねむろかいきょう）。そこにえびのような形の半島（はんとう）があります。野付半島（のつけはんとう）は、「砂嘴（さし）」とよばれる海岸（かいがん）に砂（すな）や石が積（つ）み重（かさ）なってできた地形で、長さは２８ｋｍもあり、日本一の大きさです。
                <br/><br/>
                　縄文時代（じょうもんじだい）から江戸時代（えどじだい）まで、野付半島（のつけはんとう）から国後島（くなしりとう）に船でわたり、その先の千島列島（ちしまれっとう）を通りながら世界（せかい）とつながっていました。今では、トドワラ、ナラワラという樹木（じゅもく）が広がりひっそりとしていますが、昔（むかし）は半島（はんとう）の先っぽにとてもにぎやかなまちがあったという『幻（まぼろし）のまちキラク伝説（でんせつ）』があるほどです
                <br/><br/>
                　人びとがこのように行き来したのは、ゆたかな自然（しぜん）が生み出すいろいろな食べものなどがあったからです。中でも、鮭（さけ）は、人や動物（どうぶつ）たちの命（いのち）をささえてきました。鮭（さけ）は、アイヌの昔（むかし）ばなしで「知床（しれとこ）の沖（おき）にいるカムイ（アイヌ語で「神（かみ）」のこと）からの贈（おく）り物（もの）」といわれています。「鮭（さけ）とば」や「山漬（づ）け」「鮭飯寿司（さけいずし）」など、この地域（ちいき）の暮（く）らしと鮭（さけ）は切り離（はな）すことができません。
                <br/><br/>
                　鮭（さけ）は毎年、うまれた川にもどります。この地域（ちいき）では鮭（さけ）めぐって、人と自然（しぜん）とのつながりや、さまざまな文化（ぶんか）、人と人とのかかわりあいやぶつかりあいなどのできごとがありました。
                
              </p>
              :
              <p>
                　北海道最東端、眼前に国後島（「くなしりとう」）を望む根室海峡。<br/>
                その沿岸中央部に特異な形の半島が突き出ています。強い沿岸流が運ぶ砂礫（「されき」すなや小石。）の堆積が生み出した、全長28kmに及ぶ日本最大の砂嘴（「さし」湾口の一方から、鳥のくちばしのように延びた堤防状の砂の堆積(たいせき)）《野付半島》（「のつけはんとう」）です。
                <br/><br/>
                　左右に海が迫る野付（「のつけ」）の一本道を行くと、トドワラ、ナラワラという立ち枯れた樹林が広がります。その荒涼とした光景はまさに最果ての地。
                <br/><br/>
                 　しかし縄文時代から江戸時代に至るまで、野付は国後島（「くなしりとう」）への渡海拠点となり、時にはその先の千島列島を通じ、世界とつながっていました。日本列島の東門（「ひがしもん」）として絶えず人々が往来する「道」の役割を担ってきたのです。
                 <br/><br/>
                 　その最盛期の賑わいは、かつて先端に歓楽の場があったという『幻のまちキラク伝説』として語り継がれています。時代を越え人々の往来を誘ったのは、根室海峡沿岸の山海川の恵みであり、その最大の産物は、大地と海とをつなぎ、当地のあらゆる生命を支えた鮭でした。
                 <br/><br/>
                 　アイヌの伝承で「知床の沖にいるカムイ（神）からの贈り物」とされる鮭は、いまも根室海峡沿岸の代表的生産品です。毎年12月になれば民家の軒先に干される《鮭とば》、江戸時代から伝わる鮭の塩蔵熟成法《山漬け》（「やまづけ」）、そして家ごとに受け継がれた味を持つ《鮭飯寿司》（「さけいずし」ご飯と魚・野菜・麹を混ぜて、重石をのせて漬け込み、乳酸発酵させて作る主に北国ならではの郷土料理）など、鮭は当地の暮らしに深く根付いています。
                 <br/><br/>
                 　毎年秋に繰り返される鮭の遡上（「そじょう」流れをさかのぼっていくこと）という自然の摂理の下、当地では鮭を巡って人と自然、文化と文化の衝突と交流が起こり、数々の物語と共に、根室海峡に続く幾筋もの「道」が誕生するのです。
              </p>
            ): 
            id === '2' ?
            (
              kanaSwitch ?
              <p>
                　野付半島（のつけはんとう）のおよそ１０ｋｍ北に、大きく曲（ま）がりくねったポー川が流れています。この川のまわりの地面（じめん）には、竪穴住居（たてあなじゅうきょ）の跡（あと）であるたくさんのくぼみがあります。くぼみの数は４４００を超（こ）え、日本一の数の竪穴住居（たてあなじゅうきょ）がありました。遺跡（いせき）からは、たくさんの鮭（さけ）の骨（ほね）が出てきて、１万年前からの人びとの暮（く）らしや、ここにあちらこちらから鮭（さけ）をもとめて人が集（あつ）まってきていたことがわかります。
                <br/><br/>
                　それでは、人びとはどのような道を通ってこの地域（ちいき）までやってきたのでしょうか。根室海峡（ねむろかいきょう）の海に面しているいくつものチャシ跡（あと）を見ると、それが想像（そうぞう）できます。チャシとは、高台の地面（じめん）にみぞがめぐらしてある場所（ばしょ）で、使（つか）われていたときは柵（さく）で囲（かこ）まれていたようです。戦（たたか）いのための砦（とりで）や話合いの場所（ばしょ）などに使（つか）われていたと考えられています。根室管内（ねむろかんない）の海に面（めん）しているチャシ跡（あと）を通って、たくさんの人びとが行き来したことでしょう。
              </p>
              :
              <p>
                　野付から北へ約10kmの場所に、激しく蛇行を繰り返すポー川が流れています。その流域には、無数のクレーターのようなくぼみが延々と連なる独特の景観が広がっています。このくぼみは古代の竪穴住居跡（「たてあなじゅうきょあと」）です。
                <br/><br/>
                　くぼみの数は4400を超え、日本最大の竪穴群《標津遺跡群》を形成しています。この遺跡は、一万年前から17世紀に至るまで途切れることなく人が暮らし続けた結果、いまにのこされました。遺跡を発掘すると、あらゆる時代の竪穴から大量の鮭の骨が見つかります。標津の大規模竪穴群は、毎年秋、鮭を求め各地から集まる人々の「道」の集積地であり、自然と長い共生の歴史をいまに伝えています。
                <br/><br/>
                　標津遺跡群に集まった人々の道程は、根室海峡沿岸に残るチャシ跡の存在から推測できます。チャシ跡とは崖際などを溝で区画した、13〜18世紀にかけて利用されたアイヌの遺跡です。時代とともに様々な役目を担いましたが、その本質は、コタン（村）の共有の神聖な場所としての役割にありました。
                <br/><br/>
                　チャシ跡周辺には同時代のコタンが必ず存在するだけでなく、古代の竪穴住居跡も確認できます。そこが暮らしの拠点として、長きに渡り利用され続けたことがわかるのです。北海道各地に残るチャシ跡の多くは、内陸部の河川合流付近にあり、当時川筋を「道」とする交通網が発達したことが読み取れます。
                <br/><br/>
                　しかし根室海峡沿岸では、野付や沿岸一帯の河川河口付近において、海に面してチャシ跡が築かれています。この地域では河口を湊とし、古くから海を「道」とする交通網を発達させてきたのです。
                <br/><br/>
                　海を望む根室海峡チャシ跡群の存在がつたえているのは、鮭を求め標津遺跡群へと往来した人々の道程であり、チャシ跡から見渡せる景観は、当地で繰り広げられた一万年の物語の舞台なのです。
              </p>
            ):
            id === '3' ?
            (
              kanaSwitch ?
              <p>
                　この地域（ちいき）では、おいしい鮭（さけ）がたくさんとれることから、江戸時代（えどじだい）には鮭（さけ）の漁（りょう）がたくさん行われるようになりました。根室（ねむろ）の「金刀比羅神社（ことひらじんじゃ）」や標津（しべつ）の「標津神社（しべつじんじゃ）」は、その歴史（れきし）を今に伝（つた）えています。
                <br/><br/>
                　また、江戸時代（えどじだい）の終（お）わりころには、会津藩士（あいづはんし）がやってきて、この地域（ちいき）でロシアなどの外国からの警備（けいび）をしたり、開拓（かいたく）を行ったりしました。今でも野付（のつけ）には、会津藩士（あいづはんし）の墓（はか）が残（のこ）っています。
                <br/><br/>
                　明治時代（めいじじだい）になると、別海缶詰所（べっかいかんづめしょ）ができて鮭（さけ）の缶詰（かんづめ）が世界（せかい）に輸出（ゆしゅつ）されるようになり、根室海峡（ねむろかいきょう）に面（めん）したまちは大きくさかえました。
              </p>
              :
              <p>
                　江戸時代、根室海峡沿岸に進出した和人は、当地の鮭の質・量の豊かさに驚き、ここに鮭漁の漁場を拓きます。しかしその当初の搾取的経営は、労働力となったアイヌの反感を買い、幕府をも巻き込む騒動に発展したこともありました。根室の《金刀比羅神社》（「ことひらじんじゃ」）、標津の《標津神社》（「しべつじんじゃ」）は、それぞれ海峡沿岸の一帯の鮭漁を管理した「根室上会所（ねむろかみかいしょ）」、「根室下会所（ねむろしもかいしょ）」の社を前身とし、北海道最東の海を全国へとつないだ船の「道」の歴史をいまに伝えています。
                <br/><br/>
                　18世紀以降千島列島（「ちしまれっとう」）周辺では、鮭を求め北上する和人と、ラッコの毛皮を求め南下するロシア人の衝突が繰り返されます。幕末には択捉（「えとろふ」）とその先ウルップ島の間で最初の日ロ国境が定められました。野付（「のつけ」）にある「會」旗（「かいき」）を掲げた墓石は、日本東門の国境警備と開拓を担った《会津藩士の墓》です。
                <br/><br/>
                　当時標津代官南摩綱紀（「なんまつなのり」）は、文化の異なるアイヌと和人が共に開拓に臨む、水産業のまちづくりを構想し、その思いを「標津番屋屏風」（「しべつばんやびょうぶ」）に込めます。当時鮭は高級魚で、中でも当時の鮭はその品質の良さから、各地域とは比べものにならない程の価値があり、江戸時代のブランドの一つとなっていました。《加賀家文書》（「かがけもんじょ」）には徳川将軍家にも献上されたことが記されています。南摩はこの高品質の鮭を基盤に、当時のまちの礎を築ました。
                <br/><br/>
                　明治11年、北海道開拓使が西別川河口に《別海缶詰所》（「べっかいかんづめしょ」）を開設します。工場はやがて民営化し、国後島（「くなしりとう」）を含む海峡沿岸に続々と増設され、鮭缶詰はヨーロッパやオーストラリアなど、世界市場に輸出されます。北海道最東の海から続く船の「道」は世界に延び、根室海峡沿岸一帯のまちは大きく隆盛したのです。
              </p>
            ):
            id === '4' ?
            (
              (
                kanaSwitch ?
                <p>
                  　 天然（てんねん）の鮭（さけ）をとりすぎたため、明治時代（めいじじだい）後半（こうはん）になると、鮭（さけ）があまりとれなくなってしまいました。そこで、昆布（こんぶ）やホッカイシマエビをとったり、牛をかって酪農（らくのう）などをはじめたりしました。
                  <br/><br/>
                  　大正時代（たいしょうじだい）の終（お）わりころになると、広い土地を切り拓（ひら）いて酪農（らくのう）がとてもさかんになりました。別海（べつかい）の「旧（きゅう）奥行臼（おくゆきうす）駅逓所（えきていしょ）」では、荷物（にもつ）を運（はこ）んだ馬や人が宿泊（しゅくはく）して次（つぎ）の目的地（もくてきち）を目指（めざ）したり、鉄道（てつどう）が発達（はったつ）すると蒸気機関車（じょうききかんしゃ）が走ったりするようになりました。旧（きゅう）標津線（しべつせん）の終点（しゅうてん）根室標津駅（ねむろしべつえき）には、機関車（きかんしゃ）の向（む）きを変（か）える「転車台（てんしゃだい）」が今でも残（のこ）っています。こうして、海に面（めん）した地域（ちいき）ばかりではなく、内陸部（ないりくぶ）もどんどんさかえていきました。
                  <br/><br/>
                  <StoriesNumSC>Story 5</StoriesNumSC>
                  <StoriesHeadingSC>いまも人びとは鮭（さけ）とともに</StoriesHeadingSC>
                  　昭和（しょうわ）４０年代（だい）になると、人工的（てき）に鮭（さけ）の卵（たまご）を孵化（ふか）させて川に放（はな）すことにより、再（ふたた）びたくさんの鮭（さけ）がとれるようになりました。鮭（さけ）の加工（かこう）などを行う人の数が足りなくなり、日本全国（ぜんこく）だけでなく世界（せかい）からも人が集（あつ）まってきて、鮭（さけ）にかかわる仕事（しごと）をするようになっていきました。
                  <br/><br/>
                  　この地域（ちいき）の１万年という長い歴史（れきし）をふり返（かえ）ると、いつの時代（じだい）も鮭（さけ）とのかかわりがありました。根室海峡（ねむろかいきょう）の海に面（めん）した地域（ちいき）は「鮭（さけ）の聖地（せいち）」であり、今も鮭（さけ）とかかわりながら、人びとが生活しています。
                </p>
                :
                <p>
                  　明治時代半ば以降、天然魚に頼った鮭漁は、次第に資源が枯渇します。明治24年には人工ふ化事業に着手しますが、その成果を得るのはまだ先のことでした。西欧諸国と肩を並べようと国を挙げて邁進した明治時代、日本東門の安定と発展は不可欠であり、鮭漁を補う新たな産業の確立が求められていました。
                  <br/><br/>
                  　現在根室海峡では、根室の昆布や野付湾（「のつけわん」）のホッカイシマエビなど、多彩な水産品が水揚げされています。これらは鮭資源の減少に直面した漁業者が、ここで生きるために選んだ漁の姿です。また野付で目にする《海岸の牛舎跡》は、漁業者が漁の傍ら、副業として畜産農業を行った、かつての半農半漁の暮らしの名残です。
                  <br/><br/>
                  　この畜産農業の一つ酪農が、大正末期以降、冷涼で安定した農業を阻み続けた根釧台地内陸部に拡がり、全国から集まった開拓者の手で一大産業へと成長しました。
                  <br/><br/>
                  　根釧台地の酪農景観には、北海道や国の農業政策はもとより、不屈の精神で厳しい自然と向き合い、広大な原野を切り開いた開拓者の思いと歴史が込められています。
                  <br/><br/>
                  　別海の《旧奥行臼駅逓所》（「きゅうおくゆきうすえきていしょ」）や標津の《旧根室標津駅転車台》（「きゅうねむろしべつえきてんしゃだい」）など、根釧台地の内陸交通遺産は、持続可能な産業の確立を目指し、海から大地へと展開した先人達の、内陸の「道」の歴史をいまに伝えています。
                  <br/><br/>
                  　いま根室海峡沿岸で目にする数々の一次産業は、半世紀に及ぶ鮭資源減少時代に、人々が日々の暮らしをつなぎ、当地の発展を夢見て臨んだ、新たな挑戦の結晶なのです。
                  <br/><br/>
                  <StoriesNumSC>Story 5</StoriesNumSC>
                  <StoriesHeadingSC>鮭を求める人々はいまも</StoriesHeadingSC>
                  　昭和40年代、人工ふ化事業がついに結実し、長く低迷した鮭漁は、前年比２倍もの驚異的な漁獲量更新を繰り返します。かつての高級魚は、日本の食卓を彩る最もポピュラーな食材の一つとなり、全国の店頭には日々塩鮭が並びます。
                  <br/><br/>
                  　鮭を巡る状況が大きく変わる中、かつて内陸開拓を牽引した標津線は、東北地方からの季節労働者「青森衆」を招く「道」となり、不足する鮭漁期の労働力補強に貢献しました。鉄道が廃線となったいまは、アスファルトの「道」を通り、全国そして世界から、鮭加工に従事するため集まる「シャケバイ」と呼ばれる若者達の姿が、毎年秋の風物詩となっています。
                  <br/><br/>
                  　一万年に渡り、当地で織りなされた数々の物語。そこには常に鮭との関わりがありました。鮭に笑い、鮭に泣いた根室海峡沿岸は、人も自然も、あらゆるものが鮭とつながる「鮭の聖地」であり、いまもその恵みへと通じる「道」に、人々の往来が続いています。
                </p>
              )
            ):("")
          }
        </StoriesBodySC>
        <StoryLinkWrapperSC>
          {
            id === '1' 
            && 
            <StoryLink 
              to={"/story/pages/1"}
              onClick={() => {
                dispatch(setStoryId(Number(id), 1, 7, 1))
                dispatch(setIsOnPages(true))
              }}
          
            >史跡ギャラリー</StoryLink>
          }
          {
            id === '2' 
            && 
            <StoryLink 
              to={"/story/pages/1"} 
              onClick={() => {
                dispatch(setStoryId(Number(id), 1, 6, 1))
                dispatch(setIsOnPages(true))
              }}
            >
                史跡ギャラリー
            </StoryLink>
          }
          {
            id === '3' 
            && 
            <StoryLink 
              to={"/story/pages/1"} 
              onClick={() => {
                dispatch(setStoryId(Number(id), 1, 9, 1))
                dispatch(setIsOnPages(true))
              }}
            >
                史跡ギャラリー
            </StoryLink>
          }
          {
            
            id === '4'
            && 
            <StoryLink 
              to={"/story/pages/1"} 
              onClick={() => {
                dispatch(setStoryId(Number(id), 1, 9, 1))
                dispatch(setIsOnPages(true))
              }}
            >
              史跡ギャラリー
            </StoryLink>
          }
        </StoryLinkWrapperSC>
      </StoriesContainerSC>
    </StoriesMainContainerSC>
  )
}

// =====================================
// スタイル
// =====================================
const StoriesMainContainerSC = styled.div`
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`

const StoriesContainerSC = styled.div`
  background-color: rgba(255, 255, 255, 0.88);
  padding: 24px 72px;
  width: 980px;
  position: relative;
  margin-right: 100px;
  margin-left: auto;
  @media ${breakpoints.device.pc_xl}{
    margin: 0 auto;
  }
  @media ${breakpoints.device.tab}{
    width: 90%;
  }
  @media ${breakpoints.device.tab_large_tate}{
    padding: 24px;
  }
`

const StoriesHeaderSC = styled.div`
  margin-bottom: 24px;
  @media ${breakpoints.device.iPhone5_ls}{
    margin-bottom: 14px;
  }
`

const StoriesNumSC = styled.p`
  font-size: 18px;
  margin-bottom: 20px;
  font-weight: 600;
  @media ${breakpoints.device.iPhone5_ls}{
    margin-bottom: 10px;
  }
`

const StoriesHeadingSC = styled.h2`
  font-size: 21px;
  margin-bottom: 30px;
  font-weight: 600;
  @media ${breakpoints.device.iPhone5_ls}{
    margin-bottom: 20px;
  }
`

const StoriesBodySC = styled.div`
  font-size: 16px;
  line-height: 34px;
  overflow-y: scroll;
  margin-bottom: 24px;
  overflow-y: scroll;
  height: 400px;
  @media ${breakpoints.device.tab} and ${breakpoints.device.tab_h} {
    height: 250px;
  }
  @media ${breakpoints.device.tab_yoko} and ${breakpoints.device.tab_h} {
    height: 220px;
  }
  @media ${breakpoints.device.tab_yoko} {
    height: 140px;
    line-height: 28px;
  }
  @media ${breakpoints.device.sp_yoko_xl} and ${breakpoints.device.tab_small_h} {
    height: 100px;
  }
  @media ${breakpoints.device.tab_large_tate} and ${breakpoints.device.sp_yoko_xl_h} {
    height: 100px;
  }
  @media ${breakpoints.device.iPhone5_ls}{
    height: 80px;
  }

`

const StoryLinkWrapperSC = styled.div`
  padding-top: 24px;
  text-align: center;
  border-top: solid 1px #707070;
`

const StoryLink = styled(Link)`
  display: inline-block;
  max-width: 340px;
  width: 80%;
  padding: 16px 0;
  text-align: center;
  color: white;
  background-color: #E35226;
  font-weight: bold;
  &:hover {
    opacity: 0.7;
  }
`

const Next = styled(Link)`
  width: 50px;
  height: 50px;
  position: absolute;
  text-align: center;
  padding: 16px 0;
  background-size: cover;
  background-position: center;
  top: 50%;
  bottom: 50%;
  right: -25px;
  &:hover {
    opacity: 0.7;
  }
`

const Prev = styled(Link)`
  width: 50px;
  height: 50px;
  position: absolute;
  text-align: center;
  padding: 16px 0;
  background-size: cover;
  background-position: center;
  top: 50%;
  bottom: 50%;
  left: -25px;
  &:hover {
    opacity: 0.7;
  }
`

const StoriesEnHeading = styled.h2`
  position: fixed;
  top: 10%;
  left: 5%;
  font-size: 170px;
  color: #ffffff;
  z-index: 0;
  opacity: 0.4;
  @media ${breakpoints.device.tab}{
    font-size: 130px;
    left: 10%;
    top: 50%;
    transform:translateY(-50%);
  }
  @media ${breakpoints.device.tab_large_tate}{
    font-size: 100px;
  }
  @media ${breakpoints.device.sp_tate_lg}{
    font-size: 80px;
  }
`

export default Stories
