import React from 'react'
import { Link } from 'react-router-dom';
import styled from 'styled-components'
import {useDispatch} from 'react-redux'
import {setIsOnPages} from '../../reducks/session/actions'
import breakpoints from "../mixins/breakpoints"

const GlobalLinkButtonSC = styled(Link)`
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  color: #ffffff;
  width: 340px;
  height: 60px;
  background: #E35226;
  text-decoration: none;
  z-index: 999;
  font-weight: 700;
  @media ${breakpoints.device.tab_large_tate}{
    text-align: center;
    width: 120px;
  }
  &:hover {
    opacity: 0.7;
  }
`;

const ButtonLine = styled.div`
  width: 45%;
  height: 1px;
  background: #ffffff;
  margin-top: 20px;
  @media ${breakpoints.device.tab_large_tate}{
    display: none;
  }
`;

const ButtonLabelContainer = styled.div`
  width: 40%;
  margin-left: 15px;
  padding-top: 13px;
  @media ${breakpoints.device.tab_large_tate}{
    width: 100%;
    margin-left: 0;
    padding-top: 22px;
  }
`;

const ButtonLabelJp = styled.h3`
  font-size: 16px;
  font-weight: 700;
  color: #ffffff;
  margin-top: 0;
  margin-bottom: 5px;
`;

const ButtonLabelEn = styled.h4`
  font-size: 10px;
  font-weight: 700;
  color: #ffffff;
  margin-top: 0;
  margin-bottom: 0;
  @media ${breakpoints.device.tab_large_tate}{
    display: none;
  }
`;

function GlobalLinkButton({path, labelJp, labelEn}) {
  const dispatch = useDispatch()
  return (
    <GlobalLinkButtonSC to={path} onClick={()=>dispatch(setIsOnPages(false))}>
      <ButtonLine>
      </ButtonLine>
      <ButtonLabelContainer>
        <ButtonLabelJp>{labelJp}</ButtonLabelJp>
        <ButtonLabelEn>{labelEn}</ButtonLabelEn>
      </ButtonLabelContainer>
    </GlobalLinkButtonSC>
  )
}

export default GlobalLinkButton
