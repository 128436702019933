import {SET_GRADE_ID,SET_STORY_ID, INCREMENT_CURRENT_PAGE, DECREMENT_CURRENT_PAGE, SET_IS_ON_PAGES, SET_IS_ON_HOME} from './type'

export const setGradeId = (current_grade, start_page, end_page, current_page) => {
  return {
    type: SET_GRADE_ID,
    current_grade,
    start_page,
    end_page,
    current_page,
  }
}

export const setStoryId = (current_story, start_page, end_page, current_page) => {
  return {
    type: SET_STORY_ID,
    current_story,
    start_page,
    end_page,
    current_page,
  }
}


export const setIsOnPages = (is_on_pages) => {
  return {
    type:SET_IS_ON_PAGES,
    is_on_pages
  }
}

export const setIsOnHome = (is_on_home) => {
  return {
    type: SET_IS_ON_HOME,
    is_on_home
  }
}


export const incrementCurrentPage = (current_page) => {
  return {
    type: INCREMENT_CURRENT_PAGE,
    current_page
  }
}

export const decrementCurrentPage = (current_page) => {
  return {
    type: DECREMENT_CURRENT_PAGE,
    current_page
  }
}