import React, {useRef, useEffect} from "react";
import {useSelector, useDispatch} from 'react-redux'
import GlobalLinkButton from "./parts/GlobalLinkButton";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Story1Img from "../images/stories-1.png";
import Story2Img from "../images/stories-2.png";
import Story3Img from "../images/stories-3.png";
import Story4Img from "../images/stories-4.png";
import PrevIcon from "../images/icons/prev-page.svg";
import NextIcon from "../images/icons/next-page.svg";
import {fetchPagesDataRequest, getChildPageRequest} from '../reducks/pages/actions'
import {image1,image2,image3,image4,image5,image6,image7,image8,image9,image10,image11,image12,image13,image14,image15,image16,image17,image18,image19,image20,image21,image22,image23,image24,image25,image26,image27,image28,image29,image30,image31} from './PageImages'
import color from "./mixins/color"
import breakpoints from "./mixins/breakpoints"
import {incrementCurrentPage, decrementCurrentPage} from '../reducks/session/actions'




function Pages(props) {

  const dispatch = useDispatch();

  const pages = useSelector(state => state.pages)
  const current_grade = useSelector(state => state.session.current_grade)
  const start_page = useSelector(state => state.session.start_page)
  const end_page = useSelector(state => state.session.end_page)
  const current_page = useSelector(state => state.session.current_page)

  const page_id = Number(props.match.params.page_id)
  // const SelectedPage = pages.pages.filter((page) => page.page_id === page_id)[0]
  const SelectedPage = useSelector((state) => state.pages.page);

  useEffect(() => {
    // dispatch(getPageRequest(page_id));
    dispatch(getChildPageRequest(current_grade, start_page, current_page));
  }, 
  // [dispatch, page_id]
  [dispatch, current_grade, start_page, current_page]
  )

  const scrollElm = useRef(null);
  const currentScroll = scrollElm.current

  const next_id = current_page + 1;
  const prev_id = current_page - 1;
  const kanaSwitch = useSelector(state => state.kanaSwitch);
  let pageImage;
  switch(current_grade) {
    case 3:
      switch(current_page) {
        case 1:
          pageImage = image1;
          break
        case 2:
          pageImage = image9;
          break
        case 3:
          pageImage = image5;
          break
        case 4:
          pageImage = image6;
          break
        case 5:
          pageImage = image7;
          break
        default:
      }
      break
    case 4:
      switch(current_page) {
        case 1:
          pageImage = image2;
          break
        case 2:
          pageImage = image3;
          break
        case 3:
          pageImage = image4;
          break
        case 4:
          pageImage = image14;
          break
        case 5:
          pageImage = image15;
          break
        case 6:
          pageImage = image16;
          break
        case 7:
          pageImage = image17;
          break
        case 8:
          pageImage = image22;
          break
        case 9:
          pageImage = image25;
          break
        case 10:
          pageImage = image26;
          break
        case 11:
          pageImage = image27;
          break
        case 12:
          pageImage = image28;
          break
        case 13:
          pageImage = image31;
          break
        case 14:
          pageImage = image31;
          break
        default:
      }
      break
    case 5:
      switch(current_page) {
        case 1:
          pageImage = image19;
          break
        case 2:
          pageImage = image20;
          break
        case 3:
          pageImage = image21;
          break
        case 4:
          pageImage = image23;
          break
        case 5:
          pageImage = image24;
          break
        case 6:
          pageImage = image29;
          break
        case 7:
          pageImage = image30;
          break
        default:
      }
      break
    case 6:
      switch(current_page) {
        case 1:
          pageImage = image8;
          break
        case 2:
          pageImage = image10;
          break
        case 3:
          pageImage = image11;
          break
        case 4:
          pageImage = image12;
          break
        case 5:
          pageImage = image13;
          break
        case 6:
          pageImage = image18;
          break
        default:
      }
      break
    default:
      pageImage = image1;
  }

  let page_title_first
  let page_title_second
  let page_title_third
  let page_title_fourth
  let page_title_fifth
  let page_content
  if (SelectedPage) {
    if (kanaSwitch) {
      // if(SelectedPage.title_first_kana_md && SelectedPage.title_first_kana_md !== "") {
      if((window.parent.screen.width === 960 || window.parent.screen.width === 1024) && SelectedPage.title_first_kana_md && SelectedPage.title_first_kana_md !== "") {
        page_title_first = SelectedPage.title_first_kana_md;
        page_title_second = SelectedPage.title_second_kana_md;
        page_title_third = SelectedPage.title_third_kana_md;
        page_title_fourth = SelectedPage.title_fourth_kana_md;
        page_title_fifth = SelectedPage.title_fifth_kana_md;
      } else {
        page_title_first = SelectedPage.title_first_kana;
        page_title_second = SelectedPage.title_second_kana;
      }
      page_content = SelectedPage.body_kana;
    } else {
      page_title_first = SelectedPage.title_first
      page_title_second = SelectedPage.title_second;
      page_content = SelectedPage.body;
    }
  }

  const handleNext = () => {
    console.log('clicked')
    dispatch(incrementCurrentPage(current_page))
    currentScroll.scrollTop = 0
  }

  const handlePrev = () => {
    console.log('clicked')
    dispatch(decrementCurrentPage(current_page))
    currentScroll.scrollTop = 0
  }

  return (
    <>
      {
        kanaSwitch ? 
        <GlobalLinkButton path={"/"} labelJp={"もどる"} labelEn={"Back to TOP"} />
        : <GlobalLinkButton path={"/story-select"} labelJp={"物語選択画面へ"} labelEn={"Back to Page Select"} />
      }

      <StoryPageBlurBG style={{ backgroundImage: `url(${pageImage}`}} />

      <StoryPageImg style={{ backgroundImage: `url(${pageImage}`}}>

        {
         current_page !== end_page ?
         <StoryPageNextButton 
           className="testNextButton" 
           to={`/child/pages/${next_id}`} 
           style={{backgroundImage: `url(${NextIcon})`}}
           onClick={handleNext}
           />
          : ""
        }
        {
          current_page !== start_page ? 
          <StoryPagePrevButton 
            className="testPrevButton" 
            to={`/child/pages/${prev_id}`} 
            style={{backgroundImage: `url(${PrevIcon})`}}
            onClick={handlePrev}
            />
          : ""
        }

        <StoryPageHeadingBlock>
          {
            //画面幅が960pxか1024pxのとき白帯テキストが4行以上ある場合
            (window.parent.screen.width === 960 || window.parent.screen.width === 1024) && SelectedPage.title_fourth_kana_md && SelectedPage.title_fourth_kana_md !== "" ?
            <StoryPageHeading>
              <span>{page_title_first}</span>
              <br />
              <span>{page_title_second !== "" && page_title_second}</span>
              <br />
              <span>{page_title_third !== "" && page_title_third}</span>
              <br />
              <span>{page_title_fourth !== "" && page_title_fourth}</span>
              <br />
              <span>{page_title_fifth !== "" && page_title_fifth}</span>
            </StoryPageHeading>
            //画面幅が960pxか1024pxのとき白帯テキストが3行以下の場合
            : (window.parent.screen.width === 960 || window.parent.screen.width === 1024) &&(!SelectedPage.title_fourth_kana_md && SelectedPage.title_fourth_kana_md === "") && (SelectedPage.title_first_kana_md && SelectedPage.title_first_kana_md !== "") ?
            <StoryPageHeading>
              <span>{page_title_first}</span>
              <br />
              <span>{page_title_second !== "" && page_title_second}</span>
              <br />
              <span>{page_title_third !== "" && page_title_third}</span>
            </StoryPageHeading>
            :
            <StoryPageHeading>
              <span>{page_title_first}</span>
              <br />
              <span>{page_title_second !== "" && page_title_second}</span>
            </StoryPageHeading>
          }
        </StoryPageHeadingBlock>
      </StoryPageImg>

      <StoryPageReadingBlock>
        <StoryPageReadingBlockHeading>Spot {page_id}</StoryPageReadingBlockHeading>
        <StoryPageReadingBlockContent ref={scrollElm}>
          {/* {page_content && page_content.replaceAll(/\\n/g, '<br />') } */}
          {page_content && page_content.split('\\n').map(str => {
            return(<span>{str}<br/></span>)
          })}
        </StoryPageReadingBlockContent>
      </StoryPageReadingBlock>
    </>
  );
}

export default Pages;

const StoryPageBlurBG = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  background-size: cover;
  background-position: center;
  -ms-filter: blur(20px);
  filter: blur(20px);
  z-index: 0;
`;

const StoryPageImg = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 85%;
  max-width: 1400px;
  height: 80%;
  max-height: 800px;
  margin: auto;
  content: "";
  background-size: cover;
  background-position: center;
  z-index: 1;

  // 1024以下
  @media ${breakpoints.device.tab}{
    top: -230px;
    max-height: 600px;
  }
  // 1024以下かつ高さ768以下
  @media ${breakpoints.device.tab} and ${breakpoints.device.tab_h} {
    top: -250px;
    max-height: 420px;
  }
  
  // 960以下
  @media ${breakpoints.device.tab_yoko}{
    top: -250px;
    max-height: 420px;
  }
  // 960以下かつ高さ768以下
  @media ${breakpoints.device.tab_yoko} and ${breakpoints.device.tab_h} {
    top: -130px;
    max-height: 310px;
  }

  // 812以下375以下
  @media ${breakpoints.device.sp_yoko_xl} and ${breakpoints.device.tab_small_h} {
    top: -60px;
    max-height: 240px;
  }

  // 768以下かつ高さ414以下
  @media ${breakpoints.device.tab_large_tate} and ${breakpoints.device.sp_yoko_xl_h} {
    top: -80px;
    max-height: 250px;
  }

  // 600以下
  @media ${breakpoints.device.tab_tate}{
    top: -130px;
    max-height: 310px;
  }
  
  // 320px　縦
  @media ${breakpoints.device.iPhone5_pr}{
    top: -110px;
    max-height: 300px;
  }
  // 320px　横
  @media ${breakpoints.device.iPhone5_ls}{
    top: -70px;
    max-height: 200px;
  }  

`;

const StoryPageHeadingBlock = styled.div`
  position: absolute;
  top: -30px;
  left: 80px;
  z-index: 2;
  @media ${breakpoints.device.tab_yoko}{
    height: 260px;
  }
  @media ${breakpoints.device.sp_yoko_xl} and ${breakpoints.device.tab_small_h} {
    height: 260px;
  }
  @media ${breakpoints.device.tab_large_tate}{
    height: 200px;
  }
  @media ${breakpoints.device.sp_yoko_xl} and ${breakpoints.device.tab_small_h} {
    height: 200px;
  }
  @media ${breakpoints.device.tab_tate}{
    left: 40px;
  }
  @media ${breakpoints.device.iPhone5_pr}{
    height: 160px;
  }
  @media ${breakpoints.device.iPhone5_ls}{
    height: 160px;
  }
`;

const StoryPageHeading = styled.h1`
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
  color: #062d4b;
  font-weight: 700;
  letter-spacing: 10px;
  font-size: 24px;
  max-height: 550px;
  @media ${breakpoints.device.tab}{
    font-size: 18px;
    position: relative;
    top: 30px;
    letter-spacing: 3px;
  }
  @media ${breakpoints.device.tab_yoko}{
    font-size: 17px;
  }
  @media ${breakpoints.device.sp_yoko_xl} and ${breakpoints.device.tab_small_h} {
    font-size: 17px;
  }
  @media ${breakpoints.device.tab_large_tate}{
    font-size: 16px;
  }
  @media ${breakpoints.device.tab_large_tate} and ${breakpoints.device.sp_yoko_xl_h} {
    font-size: 16px;
  }
  @media ${breakpoints.device.tab_tate}{
    font-size: 14px;
  }
  @media ${breakpoints.device.iPhone5_pr}{
    font-size: 12px;
  }
  @media ${breakpoints.device.iPhone5_ls}{
    font-size: 12px;
  }

  span {
    position: relative;
    display: inline-block;
    margin-right: 25px;
    z-index: 1;
    @media ${breakpoints.device.tab_large_tate}{
      margin-right: 15px;
    }
    &::after {
      position: absolute;
      top: -7px;
      right: auto;
      left: -9%;
      content: "";
      background: #ffffff;
      width: 120%;
      height: 105%;
      z-index: -1;
    }
  }
`;

const StoryPageReadingBlock = styled.div`
  position: fixed;
  right: 0;
  bottom: 40px;
  width: 65%;
  height: 240px;
  background: rgba(255,255,255, .7);
  z-index: 10;
  // 1024以下
  @media ${breakpoints.device.tab}{
    width: 85%;
    bottom: -600px;
    left: 0;
    top: 0;
    right: 0;
    margin: auto;
  }
  // 横幅1024以下かつ高さ768以下
  @media ${breakpoints.device.tab} and ${breakpoints.device.tab_h} {
    bottom: -400px;
  }

  // 960以下
  @media ${breakpoints.device.tab_yoko}{
    bottom: -380px;
    height: 210px;
  }
  // 960以下かつ高さ768以下
  @media ${breakpoints.device.tab_yoko} and ${breakpoints.device.tab_h} {
    bottom: -360px;
    height: 180px;
  }

  // 812以下かつ高さ375以下
  @media ${breakpoints.device.sp_yoko_xl} and ${breakpoints.device.tab_small_h} {
    bottom: -250px;
    max-height: 70px;
  }

  // 768以下かつ高さ414以下
  @media ${breakpoints.device.tab_large_tate} and ${breakpoints.device.sp_yoko_xl_h} {
    bottom: -250px;
    max-height: 80px;
  }

  // 600以下
  @media ${breakpoints.device.tab_tate}{
    bottom: -360px;
    height: 180px;
  }

  // 320px 縦
  @media ${breakpoints.device.iPhone5_pr}{
    bottom: -350px;
    height: 160px;
  }
  // 320px 横
  @media ${breakpoints.device.iPhone5_ls}{
    bottom: -210px;
    height: 80px;
  }

`;

const StoryPageReadingBlockHeading = styled.div`
  position: absolute;
  top: -20px;
  width: 240px;
  height: 36px;
  background:${color.orange};
  color: #ffffff;
  font-weight: 700;
  font-size: 21px;
  padding: 4px 8px;
  text-align: center;
  @media ${breakpoints.device.tab_tate}{
    width: 160px;
    font-size: 18px;
    padding: 7px 8px;
  }
`;

const StoryPageReadingBlockContent = styled.p`
  font-size: 18px;
  line-height: 34px;
  color: #000000;
  height: 220px;
  padding: 40px;
  width: 100%;
  overflow: scroll;
  @media ${breakpoints.device.tab_yoko}{
    height: 180px;
    padding: 25px 20px;
  }
  // 812以下375以下
  @media ${breakpoints.device.sp_yoko_xl} and ${breakpoints.device.tab_small_h} {
    max-height: 70px;
  }
  // 768以下かつ高さ414以下
  @media ${breakpoints.device.tab_large_tate} and ${breakpoints.device.sp_yoko_xl_h} {
    max-height: 70px;
  }
  @media ${breakpoints.device.tab_tate}{
    font-size: 16px;
  }
  // 320px以下 縦
  @media ${breakpoints.device.iPhone5_pr}{
    height: 140px;
  }
  // 320px以下 横
  @media ${breakpoints.device.iPhone5_ls}{
    height: 80px;
  }

`;

const StoryPageNextButton = styled(Link)`
  position: absolute;
  top: 0;
  right: -40px;
  bottom: 0;
  left: auto;
  display: block;
  width: 80px;
  height: 80px;
  margin: auto;
  background-size: cover;
  background-position: center;
  text-decoration: none;
  z-index: 10;
  &:hover {
    opacity: 0.7;
  }
  @media ${breakpoints.device.tab}{
    top: 200px;
  }
  @media ${breakpoints.device.tab_yoko}{
    bottom: 110px;
  }
  @media ${breakpoints.device.tab_tate}{
    width: 60px;
    height: 60px;
    right: -25px;
  }
  @media ${breakpoints.device.sp_tate}{
    width: 45px;
    height: 45px;
    right: -20px;
  }

`;

const StoryPagePrevButton = styled(Link)`
  position: absolute;
  top: 0;
  right: auto;
  bottom: 0;
  left: -40px;
  display: block;
  width: 80px;
  height: 80px;
  margin: auto;
  background-size: cover;
  background-position: center;
  text-decoration: none;
  z-index: 10;
  &:hover {
    opacity: 0.7;
  }
  @media ${breakpoints.device.tab}{
    top: 200px;
  }
  @media ${breakpoints.device.tab_yoko}{
    bottom: 110px;
  }
  @media ${breakpoints.device.tab_tate}{
    width: 60px;
    height: 60px;
    left: -25px;
  }
  @media ${breakpoints.device.sp_tate}{
    width: 45px;
    height: 45px;
    left: -20px;
  }
  
`;