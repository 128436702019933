import { Switch } from 'react-router-dom/cjs/react-router-dom.min'
import {INCREMENT_CURRENT_PAGE, DECREMENT_CURRENT_PAGE, SET_GRADE_ID, SET_STORY_ID, SET_IS_ON_PAGES, SET_IS_ON_HOME} from './type'

const initialState = {
  current_grade: 3,
  current_story: 1,
  start_page: 1,
  current_page: 1,
  end_page: 1,
  is_on_page: false,
  is_on_home: true
}

const sessionReducer = (state = initialState, action) => {
  switch(action.type) {
    case SET_GRADE_ID:
      return {
        current_grade: action.current_grade,
        start_page: action.start_page,
        end_page: action.end_page,
        current_page: action.current_page
      }

    case SET_STORY_ID:
      return {
        current_story: action.current_story,
        start_page: action.start_page,
        end_page:action.end_page,
        current_page: action.current_page
      }
    
    case SET_IS_ON_PAGES:
      return {
        ...state,
        is_on_pages: action.is_on_pages
      }
    case SET_IS_ON_HOME:
      return {
        ...state,
        is_on_home: action.is_on_home
      }

    case INCREMENT_CURRENT_PAGE:
      return {
        ...state,
        current_page: action.current_page + 1
      }

    case DECREMENT_CURRENT_PAGE:
      return {
        ...state,
        current_page: action.current_page - 1
      }
        
    default:
      return state
  }
}

export default sessionReducer