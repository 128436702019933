import { GET_STORY_SUCCEEDED, GET_STORY_FAILED } from "./types";

const initialState = {
  stories: [],
  activeStory: {},
}

const storyReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_STORY_SUCCEEDED:
      return state;
    case GET_STORY_FAILED:
      return state;
    default:
      return state;
  }
}

export default storyReducer;