const FETCH_PAGES_DATA_REQUEST = "FETCH_PAGES_DATA_REQUEST"
const FETCH_PAGES_DATA_SUCCEEDED = "FETCH_PAGES_DATA_SUCCEEDED"
const FETCH_PAGES_DATA_FAILED = "FETCH_PAGES_DATA_FAILED"

const GET_CHILD_PAGE_REQUEST = "GET_CHILD_PAGE_REQUEST"
const GET_CHILD_PAGE_SUCCEEDED = "GET_CHILD_PAGE_SUCCEEDED"
const GET_CHILD_PAGE_FAILED = "GET_CHILD_PAGE_FAILED"

const GET_STORY_PAGE_REQUEST = "GET_STORY_PAGE_REQUEST"
const GET_STORY_PAGE_SUCCEEDED = "GET_STORY_PAGE_SUCCEEDED"
const GET_STORY_PAGE_FAILED = "GET_STORY_PAGE_FAILED"

export { FETCH_PAGES_DATA_REQUEST, FETCH_PAGES_DATA_SUCCEEDED, FETCH_PAGES_DATA_FAILED, GET_CHILD_PAGE_REQUEST, GET_CHILD_PAGE_SUCCEEDED, GET_CHILD_PAGE_FAILED, GET_STORY_PAGE_REQUEST, GET_STORY_PAGE_SUCCEEDED, GET_STORY_PAGE_FAILED  }