import { FETCH_PAGES_DATA_REQUEST, GET_CHILD_PAGE_REQUEST, GET_STORY_PAGE_REQUEST} from "./types"

export const fetchPagesDataRequest = () => {
  return {
    type: FETCH_PAGES_DATA_REQUEST
  }
}

// export const getPageRequest = (pageId) => {
//   return {
//     type: GET_CHILD_PAGE_REQUEST,
//     pageId
//   }
// }

export const getChildPageRequest = (current_grade, start_page, current_page) => {
  return {
    type: GET_CHILD_PAGE_REQUEST,
    current_grade,
    start_page,
    current_page
  }
}

export const getStoryPageRequest = (current_story, start_page, current_page) => {
  return {
    type: GET_STORY_PAGE_REQUEST,
    current_story,
    start_page,
    current_page
  }
}