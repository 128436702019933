import React, { Component } from 'react'
import styled from 'styled-components';
import AdBg from '../images/nano.png'

export default class HomeAd extends Component {
  componentDidMount() {
    const fadeOutAd = () => {
      document.getElementById("ad").style.transitionDuration = "2s"
      document.getElementById("ad").style.opacity="0"
      document.getElementById("ad").style.visibility = "hidden"
    }
    if(sessionStorage.getItem("visited")) {
      document.getElementById("ad").style.visibility = "hidden"
    } else {
      sessionStorage.setItem("visited", "1")
      setTimeout(fadeOutAd, 2000)
    }
  }

  render() {
    return (
      <div>
        <HomeAdContainer className="" id="ad"/>
      </div>
    )
  }
}

const HomeAdContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-image: url(${AdBg});
  background-size: 500px;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 1000;
  background-color: white;
  &.hide {
    opacity: 0;
    transition-duration: 2s;
  }
`