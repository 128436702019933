import { FETCH_PAGES_DATA_SUCCEEDED, FETCH_PAGES_DATA_FAILED, GET_CHILD_PAGE_SUCCEEDED, GET_CHILD_PAGE_FAILED, GET_STORY_PAGE_SUCCEEDED, GET_STORY_PAGE_FAILED } from "./types";

const initialState = {
  pages:[],
  page: {}
}

const pageReducer= (state = initialState, action) => {
  switch(action.type) {
    case FETCH_PAGES_DATA_SUCCEEDED:
      console.log('fetchSuceeded')
      return {
        ...state,
        pages: action.pages
      };
    case FETCH_PAGES_DATA_FAILED:
      return state;

    case GET_CHILD_PAGE_SUCCEEDED:
      console.log('page', action.page);
      return {
        ...state,
        page: action.page[0]
      };
    case GET_CHILD_PAGE_FAILED:
      return state;

    case GET_STORY_PAGE_SUCCEEDED:
      console.log('page', action.page);
      return {
        ...state,
        page: action.page[0]
      };
    case GET_STORY_PAGE_FAILED:
      return state;
    default:
      return state
  }
}

export default pageReducer