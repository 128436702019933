import React, { useEffect } from 'react'
import GlobalLinkButton from './parts/GlobalLinkButton'
import {Link} from 'react-router-dom'
import {animateScroll} from 'react-scroll'
import styled, {keyframes} from 'styled-components';
import storiesData from './temporaryData/storiesData'
import {useSelector, useDispatch} from 'react-redux'
import {fetchPagesDataRequest} from '../reducks/pages/actions'
import PrevIcon from "../images/icons/prev-page.svg";
import NextIcon from "../images/icons/next-page.svg";
import color from "./mixins/color"
import breakpoints from "./mixins/breakpoints"
import {setIsOnPages, setStoryId} from "../reducks/session/actions"
import bg1 from '../images/Story_1.jpg'
import bg2 from '../images/Story_2-min.jpg'
import bg3 from '../images/Story_3-min.jpg'
import bg4 from '../images/Story_4-min.jpg'

function ChildStories(props) {
  const kanaSwitch = useSelector(state => state.kanaSwitch);
  const pages = useSelector(state => state.pages)
  console.log('pages', pages)
  const dispatch = useDispatch()
  const id = props.match.params.id
  const Story = storiesData[id - 1]
  console.log('Story', Story)

  useEffect(() => {
    dispatch(fetchPagesDataRequest())
    dispatch(setIsOnPages(false));
  },[dispatch])

  const scrollToTop = () => {
    animateScroll.scrollToTop()
  }

  
  return (
    <>
      <GlobalLinkButton path="/" labelJp="もどる" labelEn="Back to TOP" />

      <StoriesMainContainerSC style={{backgroundImage:`url(${bg1})`}}>
        <StoriesEnHeading>
          The Attractive Salmon to Notsuke
          <br />
          Peninsula and people
        </StoriesEnHeading>

        <StoriesContainerSC>
          <StoriesHeaderSC>
            <StoriesNumSC>Story 1.</StoriesNumSC>
            <StoriesHeadingSC>世界（せかい）とつながる野付半島（のつけはんとう）とみんなが大すきな鮭（さけ）</StoriesHeadingSC>
          </StoriesHeaderSC>
          <StoriesBodySC>
            <p>
              　北海道のいちばん東、目の前に国後島（くなしりとう）が見える根室海峡（ねむろかいきょう）。そこにえびのような形の半島（はんとう）があります。野付半島（のつけはんとう）は、「砂嘴（さし）」とよばれる海岸（かいがん）に砂（すな）や石が積（つ）み重（かさ）なってできた地形で、長さは２８ｋｍもあり、日本一の大きさです。
              <br/><br/>
              　縄文時代（じょうもんじだい）から江戸時代（えどじだい）まで、野付半島（のつけはんとう）から国後島（くなしりとう）に船でわたり、その先の千島列島（ちしまれっとう）を通りながら世界（せかい）とつながっていました。今では、トドワラ、ナラワラという樹木（じゅもく）が広がりひっそりとしていますが、昔（むかし）は半島（はんとう）の先っぽにとてもにぎやかなまちがあったという『幻（まぼろし）のまちキラク伝説（でんせつ）』があるほどです
              <br/><br/>
              　人びとがこのように行き来したのは、ゆたかな自然（しぜん）が生み出すいろいろな食べものなどがあったからです。中でも、鮭（さけ）は、人や動物（どうぶつ）たちの命（いのち）をささえてきました。鮭（さけ）は、アイヌの昔（むかし）ばなしで「知床（しれとこ）の沖（おき）にいるカムイ（アイヌ語で「神（かみ）」のこと）からの贈（おく）り物（もの）」といわれています。「鮭（さけ）とば」や「山漬（づ）け」「鮭飯寿司（さけいずし）」など、この地域（ちいき）の暮（く）らしと鮭（さけ）は切り離（はな）すことができません。
              <br/><br/>
              　鮭（さけ）は毎年、うまれた川にもどります。この地域（ちいき）では鮭（さけ）めぐって、人と自然（しぜん）とのつながりや、さまざまな文化（ぶんか）、人と人とのかかわりあいやぶつかりあいなどのできごとがありました。
            </p>
          </StoriesBodySC>
        </StoriesContainerSC>
        <ScrollDownSC>
          <p>Scroll</p>
          <span></span>
          <span></span>
          <span></span>
        </ScrollDownSC>  
      </StoriesMainContainerSC>

      <StoriesMainContainerSC style={{backgroundImage:`url(${bg2})`}}>
        <StoriesEnHeading>
          10,000 Years to Seek for Salmon
          <br />
          ~Symbiosis of human and nature~
        </StoriesEnHeading>
        <StoriesContainerSC>
          <StoriesHeaderSC>
            <StoriesNumSC>Story 2.</StoriesNumSC>
            <StoriesHeadingSC>１万年にもおよぶ鮭（さけ）と人とのつながり</StoriesHeadingSC>
          </StoriesHeaderSC>
          <StoriesBodySC>
            <p>
              　野付半島（のつけはんとう）のおよそ１０ｋｍ北に、大きく曲（ま）がりくねったポー川が流れています。この川のまわりの地面（じめん）には、竪穴住居（たてあなじゅうきょ）の跡（あと）であるたくさんのくぼみがあります。くぼみの数は４４００を超（こ）え、日本一の数の竪穴住居（たてあなじゅうきょ）がありました。遺跡（いせき）からは、たくさんの鮭（さけ）の骨（ほね）が出てきて、１万年前からの人びとの暮（く）らしや、ここにあちらこちらから鮭（さけ）をもとめて人が集（あつ）まってきていたことがわかります。
              <br/><br/>
              　それでは、人びとはどのような道を通ってこの地域（ちいき）までやってきたのでしょうか。根室海峡（ねむろかいきょう）の海に面しているいくつものチャシ跡（あと）を見ると、それが想像（そうぞう）できます。チャシとは、高台の地面（じめん）にみぞがめぐらしてある場所（ばしょ）で、使（つか）われていたときは柵（さく）で囲（かこ）まれていたようです。戦（たたか）いのための砦（とりで）や話合いの場所（ばしょ）などに使（つか）われていたと考えられています。根室管内（ねむろかんない）の海に面（めん）しているチャシ跡（あと）を通って、たくさんの人びとが行き来したことでしょう。
            </p>
          </StoriesBodySC>
        </StoriesContainerSC>
      </StoriesMainContainerSC>
      
      <StoriesMainContainerSC style={{backgroundImage:`url(${bg3})`}}>
        <StoriesEnHeading>
          Industries Nurtured by
          <br />
          Aizu Clan
        </StoriesEnHeading>
        <StoriesContainerSC>
          <StoriesHeaderSC>
            <StoriesNumSC>Story 3.</StoriesNumSC>
            <StoriesHeadingSC>会津藩士（あいづはんし）がやってきてさかんになった文化</StoriesHeadingSC>
          </StoriesHeaderSC>
          <StoriesBodySC>
            <p>
              　この地域（ちいき）では、おいしい鮭（さけ）がたくさんとれることから、江戸時代（えどじだい）には鮭（さけ）の漁（りょう）がたくさん行われるようになりました。根室（ねむろ）の「金刀比羅神社（ことひらじんじゃ）」や標津（しべつ）の「標津神社（しべつじんじゃ）」は、その歴史（れきし）を今に伝（つた）えています。
              <br/><br/>
              　また、江戸時代（えどじだい）の終（お）わりころには、会津藩士（あいづはんし）がやってきて、この地域（ちいき）でロシアなどの外国からの警備（けいび）をしたり、開拓（かいたく）を行ったりしました。今でも野付（のつけ）には、会津藩士（あいづはんし）の墓（はか）が残（のこ）っています。
              <br/><br/>
              　明治時代（めいじじだい）になると、別海缶詰所（べっかいかんづめしょ）ができて鮭（さけ）の缶詰（かんづめ）が世界（せかい）に輸出（ゆしゅつ）されるようになり、根室海峡（ねむろかいきょう）に面（めん）したまちは大きくさかえました。
            </p>
          </StoriesBodySC>
        </StoriesContainerSC>
      </StoriesMainContainerSC>

      <StoriesMainContainerSC style={{backgroundImage:`url(${bg4})`}}>
        <StoriesEnHeading>
        Salmon Story To the Earth
          <br />
          Future for Re-Symbosis
        </StoriesEnHeading>
        <StoriesContainerSC>
          <StoriesHeaderSC>
            <StoriesNumSC>Story 4.</StoriesNumSC>
            <StoriesHeadingSC>鮭（さけ）だけではなく、昆布漁（こんぶりょう）やエビ漁（りょう）、酪農業（らくのうぎょう）などもさかんになる</StoriesHeadingSC>
          </StoriesHeaderSC>
          <StoriesBodySC>
            <p>
              　 天然（てんねん）の鮭（さけ）をとりすぎたため、明治時代（めいじじだい）後半（こうはん）になると、鮭（さけ）があまりとれなくなってしまいました。そこで、昆布（こんぶ）やホッカイシマエビをとったり、牛をかって酪農（らくのう）などをはじめたりしました。
              <br/><br/>
              　大正時代（たいしょうじだい）の終（お）わりころになると、広い土地を切り拓（ひら）いて酪農（らくのう）がとてもさかんになりました。別海（べつかい）の「旧（きゅう）奥行臼（おくゆきうす）駅逓所（えきていしょ）」では、荷物（にもつ）を運（はこ）んだ馬や人が宿泊（しゅくはく）して次（つぎ）の目的地（もくてきち）を目指（めざ）したり、鉄道（てつどう）が発達（はったつ）すると蒸気機関車（じょうききかんしゃ）が走ったりするようになりました。旧（きゅう）標津線（しべつせん）の終点（しゅうてん）根室標津駅（ねむろしべつえき）には、機関車（きかんしゃ）の向（む）きを変（か）える「転車台（てんしゃだい）」が今でも残（のこ）っています。こうして、海に面（めん）した地域（ちいき）ばかりではなく、内陸部（ないりくぶ）もどんどんさかえていきました。
              <br/><br/>
              <StoriesNumSC>Story 5</StoriesNumSC>
              <StoriesHeadingSC>いまも人びとは鮭（さけ）とともに</StoriesHeadingSC>
              　昭和（しょうわ）４０年代（だい）になると、人工的（てき）に鮭（さけ）の卵（たまご）を孵化（ふか）させて川に放（はな）すことにより、再（ふたた）びたくさんの鮭（さけ）がとれるようになりました。鮭（さけ）の加工（かこう）などを行う人の数が足りなくなり、日本全国（ぜんこく）だけでなく世界（せかい）からも人が集（あつ）まってきて、鮭（さけ）にかかわる仕事（しごと）をするようになっていきました。
              <br/><br/>
              　この地域（ちいき）の１万年という長い歴史（れきし）をふり返（かえ）ると、いつの時代（じだい）も鮭（さけ）とのかかわりがありました。根室海峡（ねむろかいきょう）の海に面（めん）した地域（ちいき）は「鮭（さけ）の聖地（せいち）」であり、今も鮭（さけ）とかかわりながら、人びとが生活しています。
            </p>
          </StoriesBodySC>
        </StoriesContainerSC>
        <ScrollToTopSC>
          <span onClick={scrollToTop}></span>
          <p onClick={scrollToTop}>うえにもどる</p>
        </ScrollToTopSC>
      </StoriesMainContainerSC>
    </>
  )
}

// =====================================
// スタイル
// =====================================
const StoriesMainContainerSC = styled.div`
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
`

const StoriesContainerSC = styled.div`
  background-color: rgba(255, 255, 255, 0.88);
  padding: 24px 72px;
  width: 980px;
  position: relative;
  margin-right: 100px;
  margin-left: auto;
  @media ${breakpoints.device.tab}{
    margin: 0 auto;
    width: 90%;
  }
  @media ${breakpoints.device.sp_tate_xl}{
    padding: 24px 24px;
  }
  @media ${breakpoints.device.iPhone5_ls}{
    padding: 18px 18px;
  }
`

const StoriesHeaderSC = styled.div`
  margin-bottom: 24px;
`

const StoriesNumSC = styled.p`
  font-size: 18px;
  margin-bottom: 20px;
  font-weight: 600;
  @media ${breakpoints.device.sp_tate_xl}{
    font-size: 14px;
    margin-bottom: 16px; 
  }
  @media ${breakpoints.device.iPhone5_ls}{
    font-size: 12px;
    margin-bottom: 8px;
  }
`

const StoriesHeadingSC = styled.h2`
  font-size: 21px;
  margin-bottom: 30px;
  font-weight: 600;
  @media ${breakpoints.device.sp_tate_xl}{
    font-size: 16px;
    margin-bottom: 21px;
  }
  @media ${breakpoints.device.iPhone5_ls}{
    font-size: 14px;
  }
`

const StoriesBodySC = styled.div`
  font-size: 16px;
  line-height: 34px;
  overflow-y: scroll;
  margin-bottom: 24px;
  overflow-y: scroll;
  height: 400px;
  @media ${breakpoints.device.tab_yoko} {
    height: 300px;
  }
  @media ${breakpoints.device.sp_tate_xl}{
    font-size: 14px;
    line-height: 28px;
  }
  @media ${breakpoints.device.sp_ls} {
    height: 200px;
  }
  @media ${breakpoints.device.iPhone5_ls} {
    height: 162px;
    font-size: 12px;
    margin-bottom: 12px; 
  }
`

const StoriesEnHeading = styled.h2`
  position: absolute;
  top: 10%;
  left: 5%;
  font-size: 150px;
  color: #ffffff;
  z-index: 0;
  opacity: 0.4;
  @media ${breakpoints.device.tab}{
    font-size: 130px;
  }
  @media ${breakpoints.device.tab_yoko}{
    font-size: 110px;
  }
  @media ${breakpoints.device.tab_large_tate}{
    font-size: 90px;
  }
`

const scrollArrow = keyframes`
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
}
`

const ScrollDownSC = styled.div`
  padding-top: 80px;
  p {
    position: absolute;
    bottom: 72px;
    left: 50%;
    transform: translateX(-50%);
    color: #fff;
    @media ${breakpoints.device.sp_ls} {
      display: none;
    }
    @media ${breakpoints.device.sp_pr} {
      display: none;
    }
  }
  span { 
    position: absolute;
    bottom: 44px;
    left: 50%;
    width: 24px;
    height: 24px;
    margin-left: -12px;
    border-left: 1px solid #fff;
    border-bottom: 1px solid #fff;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-animation: sdb 2s infinite;
    animation: ${scrollArrow} 2s infinite;
    opacity: 0;
    box-sizing: border-box;
    &:nth-of-type(1) {
      -webkit-animation-delay: 0s;
      animation-delay: 0s;
    }
    &:nth-of-type(2) {
      bottom: 28px;
      -webkit-animation-delay: .15s;
      animation-delay: .15s;
    }
    &:nth-of-type(3) {
      bottom: 12px;
      -webkit-animation-delay: .3s;
      animation-delay: .3s;
    }
    @media ${breakpoints.device.sp_ls} {
      border-left: 1px solid #66ACE0;
      border-bottom: 1px solid #66ACE0;
    }
  }
`

const ScrollToTopSC = styled.div`
  padding-top: 60px;
  p {
    position: absolute;
    color: #FFF;
    bottom: 80px;
    right: 25px;
    cursor: pointer;
    @media ${breakpoints.device.sp_ls}{
      display: none;
    }
    @media ${breakpoints.device.sp_pr}{
      display: none;
    }
  }
  span {
    cursor: pointer;
    position: absolute;
    bottom: 30px;
    right: 50px;
    width: 46px;
    height: 46px;
    margin-left: -23px;
    border: 1px solid #fff;
    border-radius: 100%;
    box-sizing: border-box;
    &::after {
      position: absolute;
      top: 50%;
      left: 50%;
      content: '';
      width: 16px;
      height: 16px;
      margin: -7px 0 0 -8px;
      border-left: 1px solid #fff;
      border-bottom: 1px solid #fff;
      -webkit-transform: rotate(135deg);
      transform: rotate(135deg);
      box-sizing: border-box;
      @media ${breakpoints.device.sp_ls}{
        width: 11px;
        height: 11px;
        margin: -3px 0 0 -6px;
        border-left: 1px solid #66ACE0;
        border-bottom: 1px solid #66ACE0;
      }
      @media ${breakpoints.device.sp_pr}{
        width: 11px;
        height: 11px;
        margin: -3px 0 0 -6px;
        border-left: 1px solid #66ACE0;
        border-bottom: 1px solid #66ACE0;
      }
    }
    @media ${breakpoints.device.sp_ls}{
      bottom: 2px;
      right: 50%;
      transform: translateX(50%);
      width: 30px;
      height: 30px;
      border: 1px solid #66ACE0;
      background-color: rgba(255, 255, 255, 0.6);
    }
    @media ${breakpoints.device.sp_pr}{
      bottom: 16px;
      right: 50%;
      transform: translateX(50%);
      width: 30px;
      height: 30px;
      border: 1px solid #66ACE0;
      background-color: rgba(255, 255, 255, 0.8);
    }
  }
`

export default ChildStories
