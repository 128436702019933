import rsf from '../rsf'
import firebase from "firebase"
import{ all, call, put, takeLatest } from "redux-saga/effects"
import { FETCH_PAGES_DATA_REQUEST, FETCH_PAGES_DATA_SUCCEEDED, FETCH_PAGES_DATA_FAILED, GET_CHILD_PAGE_REQUEST, GET_CHILD_PAGE_SUCCEEDED, GET_CHILD_PAGE_FAILED, GET_STORY_PAGE_REQUEST, GET_STORY_PAGE_SUCCEEDED, GET_STORY_PAGE_FAILED } from '../reducks/pages/types'

const db = firebase.firestore()
console.log('db',db)

function* fetchPages() {
  try {
    console.log("requesting")
    const snapshot = yield call(rsf.firestore.getDocument, db.collection(`pages`))
    let pages = []
    snapshot.forEach(page => {
      pages = [...pages, {...page.data()}]
    })
    yield put ({type: FETCH_PAGES_DATA_SUCCEEDED, pages: pages})
  } catch(e) {
    console.log("e", e.message)
    yield put ({type: FETCH_PAGES_DATA_FAILED, message: e.message})
  }
}

// function* getPage(action) {
//   try {
//     const snapshot = yield call(rsf.firestore.getDocument, `pages/${action.pageId}`)
//     const page = snapshot.data()
//     yield put ({type: GET_CHILD_PAGE_SUCCEEDED, page: page})
//   } catch(e) {
//     console.log("e", e.message)
//     yield put ({type: GET_CHILD_PAGE_FAILED, message: e.message})
//   }
// }

function* getChildPage(action) {
  try {
    const snapshot = yield call(
      rsf.firestore.getCollection, 
      db.collection('pages/')
      .where('grade_id', '==', action.current_grade)
      .where('page_order', '==', action.current_page))
      console.log('snapshot', snapshot)
      let page = []
      snapshot.forEach(element => {
        page = [...page, {...element.data()}]
      });
      console.log('page',page)
      yield put ({type: GET_CHILD_PAGE_SUCCEEDED, page: page})
  } catch(e) {
    console.log("e", e.message)
    yield put ({type: GET_CHILD_PAGE_FAILED, message: e.message})
  }
}

function* getStoryPage(action) {
  try {
    const snapshot = yield call(
      rsf.firestore.getCollection, 
      db.collection('pages/')
      .where('story_id', '==', action.current_story)
      .where('story_page_order', '==', action.current_page))
      console.log('snapshot', snapshot)
      let page = []
      snapshot.forEach(element => {
        page = [...page, {...element.data()}]
      });
      console.log('page',page)
      yield put ({type: GET_STORY_PAGE_SUCCEEDED, page: page})
  } catch(e) {
    console.log("e", e.message)
    yield put ({type: GET_STORY_PAGE_FAILED, message: e.message})
  }
}



const pageSagas = [takeLatest(FETCH_PAGES_DATA_REQUEST, fetchPages), takeLatest(GET_CHILD_PAGE_REQUEST, getChildPage), takeLatest(GET_STORY_PAGE_REQUEST, getStoryPage)]

export default pageSagas