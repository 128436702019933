import { createBrowserHistory } from 'history'
import { connectRouter, routerMiddleware } from 'connected-react-router';

import { createStore } from "@reduxjs/toolkit"
import { combineReducers, applyMiddleware, compose } from "redux"

import rootSaga from "./sagas/rootSaga"
import logger from "redux-logger"
import createSagaMiddleware from "redux-saga"

import story from "./reducks/story";
import kanaSwitch from "./reducks/kanaSwitch";
import pages from "./reducks/pages"
import session from "./reducks/session"


// Devtoolsを使用するために宣言
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

// SagaのMiddleware宣言
const sagaMiddleware = createSagaMiddleware()

// Reducerを一つにまとめる
const reducer = combineReducers({
  story,
  pages,
  kanaSwitch,
  session
})


const persistConfig = {
  key: 'root',
  // storage,
  whitelist: [
    'session',
    'user',
    'members',
    'company',
    'grid',
    'filters',
    'results',
    'comments',
    'products',
    'filter',
    // 'cost',
    'funnels',
    // 'revenue_monthly',
    // 'revenue_weekly',
    // 'revenue_daily',
    // 'arpu_monthly',
    // 'arpu_weekly',
    // 'arpu_daily',
    'target_data',
    'target_forecast',
    'raw_data',
    'forecasts',
  ]
};

// const persistedReducer = persistReducer(persistConfig, rootReducer(history));

// Middlewareを１つにまとめる
const middlewares = [logger, sagaMiddleware]


// Storeの設定
const configureStore = () => {
  const store = createStore(reducer, composeEnhancers(applyMiddleware(...middlewares)))
  sagaMiddleware.run(rootSaga)

  return store
}
export default configureStore
