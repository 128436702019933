import React,{useEffect} from "react";
import { useSelector } from "react-redux";
import GlobalLinkButton from "./parts/GlobalLinkButton";
import styled, { keyframes } from "styled-components";
import { Link } from "react-router-dom";
import { fetchPagesDataRequest } from "../reducks/pages/actions"
import { useDispatch } from "react-redux"
import breakpoints from "./mixins/breakpoints"
import Hero1 from "../images/Hero1.jpg";
import Hero2 from "../images/Hero2.jpg";
import Hero3 from "../images/Hero3.jpg";
import Hero4 from "../images/Hero4.jpg";
import color from "./mixins/color"

import JapnHeritage from "../images/icons/japanheritage.png";

import HomeAd from "./HomeAd";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {setIsOnHome, setIsOnPages} from '../reducks/session/actions'
import {setGradeId} from "../reducks/session/actions"

function Home() {
  const dispatch = useDispatch()

  const kanaSwitch = useSelector(state => state.kanaSwitch)

  const handleClick = () => {
    dispatch(setIsOnHome(false))
    dispatch(fetchPagesDataRequest())
  }

  const handleGrade3 = () => {
    dispatch(setGradeId(3, 1, 5, 1))
    dispatch(setIsOnHome(false))
    dispatch(setIsOnPages(true))
  }
  const handleGrade4 = () => {
    dispatch(setGradeId(4, 1, 13, 1))
    dispatch(setIsOnHome(false))
    dispatch(setIsOnPages(true))
  }
  const handleGrade5 = () => {
    dispatch(setGradeId(5, 1, 7, 1))
    dispatch(setIsOnHome(false))
    dispatch(setIsOnPages(true))
  }
  const handleGrade6 = () => {
    dispatch(setGradeId(6, 1, 6, 1))
    dispatch(setIsOnHome(false))
    dispatch(setIsOnPages(true))
  }

  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slideToShow: 1,
    slideToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4450,
    fade: true,
    easing: "linear"
  }

  useEffect(() => {
    // dispatch(getPageRequest(page_id));
    dispatch(setIsOnPages(false));
    dispatch(setIsOnHome(true))
  }, 
  // [dispatch, page_id]
  [dispatch]
  )

  return (
    <>
      
      {/* {
        !kanaSwitch && <GlobalLinkButton path={"/stories/1"} labelJp={"はじめから読む"} labelEn={"Read the Story"} />
      } */}
      
      <HomeCover></HomeCover>

      <HomeAd />

      <HomeMain>

        <WhiteBorder />
        <OrangeBorder />

        {/* 背景写真 */}
        <BgWrapper>
          <Slider {...settings}>
            <StoryHeroImg className="hero1" />
            <StoryHeroImg className="hero2" />
            <StoryHeroImg className="hero3" />
            <StoryHeroImg className="hero4" />
          </Slider>
        </BgWrapper>

        {/* コンテンツ */}
        <HomeMainContent>
          <HomeHeadingJp>鮭の聖地の物語</HomeHeadingJp>
          <HomeHeadingEn>
            A Heritage 
            <span> </span>
            <br className="sp" />
            of Salmon
          </HomeHeadingEn>
          {
            !kanaSwitch && <SelectStoryButton to={"/story-select"} onClick={handleClick}>ストーリーを選ぶ</SelectStoryButton>
          }

          {
            kanaSwitch &&
            <GradeSelectButtonList>
              <GradeSelectButtonItem >
                <GradeSelectButton 
                  to={"/child/pages/1"} 
                  onClick={handleGrade3}
                  >３年生の教材</GradeSelectButton>
                <GradeDescription>３年生の社会科では、わたしたちがくらしているまちの今とむかしのようすなどについて学びます。</GradeDescription>
              </GradeSelectButtonItem>

              <GradeSelectButtonItem>
                <GradeSelectButton 
                  to={"/child/pages/1"} 
                  onClick={handleGrade4}
                  >４年生の教材
                </GradeSelectButton>
                <GradeDescription>４年生の社会科では、わたしたちが生活している北海道のいろいろな場所やたいせつに受けついできたものなどについて学びます。</GradeDescription>
              </GradeSelectButtonItem>

              <GradeSelectButtonItem>
                <GradeSelectButton 
                  to={"/child/pages/1"} 
                  onClick={handleGrade5}
                >５年生の教材
                </GradeSelectButton>
                <GradeDescription>５年生の社会科では、日本のさまざまな産業の様子や特色などについて学びます。</GradeDescription>
              </GradeSelectButtonItem>

              <GradeSelectButtonItem>
                <GradeSelectButton 
                  to={"/child/pages/1"} 
                  onClick={handleGrade6}
                >６年生の教材
                  </GradeSelectButton>
                <GradeDescription>６年生の社会科では、日本の政治や歴史などについて学びます。</GradeDescription>
              </GradeSelectButtonItem>

            </GradeSelectButtonList>
            
          }

          
        </HomeMainContent>
      </HomeMain>

      <JapnHeritageLink target="_blank" to="https://japan-heritage.bunka.go.jp/ja/index.html">
        <img src={JapnHeritage} alt="JapnHeritage"/>
        <p>「鮭の聖地の物語」は、<br />日本遺産に選定された<br />北海道東部の物語です</p>
      </JapnHeritageLink>
    </>
  );
}

export default Home;

// ===============================================================
// スタイル
// ===============================================================

const HomeCover = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  bottom: 0;
  left: auto;
  width: calc(100% - 80px);
  height: 100%;
  background: -moz-linear-gradient(-45deg, rgba(0, 29, 51, 0.2) 0%, rgba(84, 84, 84, 0.7) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(-45deg, rgba(0, 29, 51, 0.2) 0%, rgba(84, 84, 84, 0.7) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(135deg, rgba(0, 29, 51, 0.2) 0%, rgba(84, 84, 84, 0.7) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#99001d33', endColorstr='#545454',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
  z-index: 1;
  @media ${breakpoints.device.sp_ls} {
    width: 100%;
  }
  @media ${breakpoints.device.sp_pr} {
    width: 100%;
  }
`;

const HomeMain = styled.div`
  position: relative;
  width: 100%;
  margin-right: 0;
  margin-left: auto;
  height: 100vh;
  overflow: hidden;
  @media ${breakpoints.device.sp_ls} {
    overflow: hidden;
  }
`;

const BgWrapper = styled.div`
  button {
    .slick-arrow {
      display: none;
    }
  }
`

const StoryHeroImg = styled.div`
  position: relative;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  content: '';
  background-size: cover;
  background-position: center;
  z-index: 0;
  &.hero1 {
    background-image: url(${Hero1});
  }
  &.hero2 {
    background-image: url(${Hero2});
  }
  &.hero3 {
    background-image: url(${Hero3});
  }
  &.hero4 {
    background-image: url(${Hero4});
  }
  @media ${breakpoints.device.sp_yoko} {
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
  }
`;

const HomeMainContent = styled.div`
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  padding-top: 12%;
  padding-left: 200px;
  z-index: 2;
  @media ${breakpoints.device.tab}{
    padding: 80px 16px;
  }
  @media ${breakpoints.device.sp_pr}{
    padding: 150px 0 0 0;
  }
  @media ${breakpoints.device.sp_ls}{
    padding: 120px 0 0 0;
  }
  @media ${breakpoints.device.iPhone5_pr}{
    padding: 120px 0 0 0;
  }
  @media ${breakpoints.device.iPhone5_ls}{
    padding: 90px 0 0 0;
  }
`;

const HomeHeadingJp = styled.h1`
  font-size: 30px;
  font-weight: 700;
  color: #ffffff;
  margin-bottom: 30px;
  @media ${breakpoints.device.tab}{
    font-size: 20px;
  }
  @media ${breakpoints.device.sp_ls}{
    font-size: 14px;
    margin-bottom: 10px;
    text-align: center;
  }
  @media ${breakpoints.device.sp_pr}{
    font-size: 14px;
    margin-bottom: 10px;
    text-align: center;
  }
`;
const HomeHeadingEn = styled.h2`
  font-size: 90px;
  font-weight: 700;
  color: #ffffff;
  margin-bottom: 40px;
  @media ${breakpoints.device.tab}{
    font-size: 72px;
    margin-bottom: 10%;
  }
  @media ${breakpoints.device.sp_pr}{
    font-size: 36px;
    margin-bottom: 20px;
    text-align: center;
    br {
      display: none;
    }
  }
  @media ${breakpoints.device.sp_ls}{
    font-size: 44px;
    margin-bottom: 20px;
    text-align: center;
    br {
      display: none;
    }
  }
  @media ${breakpoints.device.iPhone5_ls}{
    font-size: 36px;
    margin-bottom: 20px;
    text-align: center;
    br {
      display: none;
    }
  }
`;

const SelectStoryButton = styled(Link)`
  display: block;
  width: 340px;
  height: 72px;
  background:${color.orange};
  text-decoration: none;
  text-align: center;
  color: #ffffff;
  font-weight: 700;
  font-size: 21px;
  padding-top: 24px;

  @media ${breakpoints.device.sp_pr}{
    height: 64px;
    width: 84%;
    margin: auto;
    padding-top: 22px;
    position: relative;
    top: 12%;
  }

  &:hover {
    opacity: .6;
  }
`;

const GradeSelectButtonList = styled.ul`
  display: flex;
  justify-content: left;
  margin-top: 40px;
  @media ${breakpoints.device.tab}{
    justify-content: space-between;
    flex-wrap: wrap;
  }

  @media ${breakpoints.device.sp_ls}{
    margin-top: 0;
    padding: 0 12px;
    justify-content: space-around;
  }

  @media ${breakpoints.device.sp_pr}{
    margin-top: 0;
    padding: 0 12px;
    justify-content: space-around;
  }
`;

const GradeSelectButtonItem = styled.li`
  width: 260px;
  margin-right: 20px;
  margin-bottom: 12px;
  
  @media ${breakpoints.device.tab}{
    width: 45%;
  }

  @media ${breakpoints.device.tab_tate}{
    width: 100%;
  }

  @media ${breakpoints.device.sp_ls}{
    width: 45%;
    margin-bottom: 20px;
  }

  @media ${breakpoints.device.sp_pr}{
    margin-right: 0px;
    margin-bottom: 20px;
  }
`;

const GradeSelectButton = styled(Link)`
  display: block;
  width: 100%;
  height: 60px;
  background: transparent;
  text-decoration: none;
  text-align: center;
  color: #ffffff;
  border: 2px solid #ffffff;
  font-weight: 700;
  font-size: 16px;
  padding-top: 18px;
  margin-bottom: 12px;
  &:hover {
    background:${color.orange};
    color: #ffffff;
    border: 2px solid${color.orange};
  }
  @media ${breakpoints.device.tab}{
    height: 40px;
    font-size: 12px;
    padding-top: 12px;
  }

  @media ${breakpoints.device.tab_tate}{
    height: 60px;
    font-size: 15px;
    padding-top: 20px;
  }
/* 
  @media ${breakpoints.device.iPhone8plus_pr}{
    height: 60px;
    font-size: 15px;
    padding-top: 20px;
  } */

  @media ${breakpoints.device.iPhone5_ls}{
    height: 45px;
    font-size: 12px;
    padding-top: 14px;
  }

  @media ${breakpoints.device.iPhone5_pr}{
    height: 45px;
    font-size: 12px;
    padding-top: 14px;
  }
`;

const GradeDescription = styled.p`
  color: #FFFFFF;
  font-size: 14px;
  line-height: 21px;
  @media ${breakpoints.device.tab_yoko}{
    font-size: 12px;
    line-height: 18px;
  }
  @media ${breakpoints.device.tab_tate}{
    display: none;
  }
  @media ${breakpoints.device.sp_ls}{
    display: none;
  }
  
`


const WhiteBorder = styled.div`
  position: absolute;
  top: 12%;
  left: 100px;
  content: '';
  background: #ffffff;
  width: 2px;
  height: 600px;
  z-index: 2;
  @media ${breakpoints.device.tab}{
    width: 600px;
    height: 2px;
    font-size: 20px;
    left: 12px;
    top: 60px;
  }
  @media ${breakpoints.device.sp_pr}{
    width: 300px;
    height: 2px;
    font-size: 20px;
    left: 50%;
    transform: translateX(-50%);
    top: 100px;
  }

  @media ${breakpoints.device.sp_ls}{
    width: 300px;
    height: 2px;
    font-size: 20px;
    left: 50%;
    transform: translateX(-50%);
    top: 100px;
  }

  @media ${breakpoints.device.iPhone5_ls}{
    width: 300px;
    height: 2px;
    font-size: 20px;
    left: 50%;
    transform: translateX(-50%);
    top: 70px;
  }



`;

const orangeborder = keyframes`
  0% { height: 0; }
  100% { height: 600px; }
`;

const orangeborderTab = keyframes`
  0% { width: 0; }
  100% { width: 600px; }
`

const orangeborderSp = keyframes`
  0% { width: 0; }
  100% { width: 300px; }
`

const OrangeBorder = styled.div`
  position: absolute;
  top: 12%;
  left: 100px;
  content: '';
  background:${color.orange};
  width: 2px;
  height: 200px;
  z-index: 3;
  animation: 4.5s ${orangeborder} infinite linear;

  @media ${breakpoints.device.tab}{
    width: 600px;
    height: 2px;
    left: 12px;
    top: 60px;
    font-size: 20px;
    animation: 4.5s ${orangeborderTab} infinite linear;
  }

  @media ${breakpoints.device.sp_ls}{
    width: 600px;
    height: 2px;
    left: 50%;
    transform: translateX(-50%);
    top: 100px;
    font-size: 20px;
    animation: 4.5s ${orangeborderSp} infinite linear;
  }

  @media ${breakpoints.device.iPhone5_ls}{
    width: 300px;
    height: 2px;
    left: 50%;
    transform: translateX(-50%);
    top: 70px;
    font-size: 20px;
    animation: 4.5s ${orangeborderSp} infinite linear;
  }

  @media ${breakpoints.device.sp_pr}{
    width: 300px;
    height: 2px;
    left: 50%;
    transform: translateX(-50%);
    top: 100px;
    font-size: 20px;
    animation: 4.5s ${orangeborderSp} infinite linear;
  }

`;

const JapnHeritageLink = styled(Link)`
  position: fixed;
  display: block;
  width: 150px;
  right: 12px;
  top: 12px;
  z-index: 99;

  /* @media ${breakpoints.device.tab} and (orientation: landscape){
    bottom: 20px;
  } */
  @media ${breakpoints.device.tab_large_tate}{
    position: fixed;
    bottom: 40px;
    top: auto;
    width: 140px;
    height: 210px;
  }
  @media ${breakpoints.device.tab_tate} {
    display: none;
  }
  @media ${breakpoints.device.sp_ls}{
    display: none;
  }
  @media ${breakpoints.device.sp_pr}{
    display: none;
  }

  &:hover {
    opacity: .7;
  }
  img {
    width: 85%;
    display: block;
    margin: auto;
  }
  p {
    font-size: 12px;
    text-align: center;
    color: #ffffff;
    font-weight: 600;
    line-height: 1.4;
    margin-top: 5px;
    @media ${breakpoints.device.tab_yoko} {
      font-size: 9px;
    }
  }

`;
